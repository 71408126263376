export const LANGUAGE_DEFAULT = "en";

export const LANGUAGES = [
    {
        label: "Thai",
        id: "th",
        locale: "th",
        code: "TH",
        default: true,
    },
    {
        label: "English",
        id: "en",
        locale: "en",
        code: "EN",
    },
];

export const SOCIAL_LINKS = [
    {
        id: "facebook",
        url: "http://www.facebook.com",
        icon: "/images/social/facebook.png",
    },
    {
        id: "instagram",
        url: "http://www.facebook.com",
        icon: "/images/social/instagram.png",
    },
    {
        id: "line",
        url: "http://www.facebook.com",
        icon: "/images/social/line.png",
    },
];

export const CONTACT_LINKS = [
    {
        id: "messenger",
        url: "https://www.messenger.com/",
        icon: "/images/social/facebook-messenger.svg",
    },
    {
        id: "line",
        url: "https://www.line.com",
        icon: "/images/social/line.svg",
    },
    {
        id: "phone",
        url: "tel:",
        icon: "/images/social/phone.svg",
    },
];

export enum UploadType {
    OTHER = "OTHER",
    // modules
    ORDER = "ORDER",
    QUOTATION = "QUOTATION",
    BOOKING = "BOOKING",
}

export const APP_WIDE_SCREEN_WIDTH = 576;
