import { FormikProps } from "formik";
import { sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import * as Yup from "yup";
import AddressAPI from "../../../../api/address/AddressAPI";
import AddressFormInput from "../../../../components/address/AddressFormInput";
import { ICountry } from "../../../../interface/address";

export interface IResidenceAddressFormProps {
    customerForm: FormikProps<any>;
}

export const ResidenceAddressSchema = Yup.object().nullable().shape({
    // country: Yup.object().required("Required field!"),
});

const ResidenceAddressForm: React.FC<IResidenceAddressFormProps> = ({
    customerForm,
}) => {
    const formValues = customerForm?.values;
    const formErrors = customerForm?.errors;
    const [countryList, setCountryList] = useState<any[]>([]);

    const addressValues = formValues?.residenceAddress ?? {};
    const addressErrors = formErrors?.residenceAddress ?? {};

    useEffect(() => {
        loadCountryList();
    }, []);

    const loadCountryList = () => {
        AddressAPI.countryList({
            page: 1,
            limit: 10000,
        }).then((resp) => {
            setCountryList(sortBy(resp, ["name"]));
        });
    };

    return (
        <div className="w-full">
            <AddressFormInput
                classNameInput="col-sm-12"
                formDataValue={addressValues}
                formDataError={addressErrors}
                countryList={countryList as ICountry[]}
                onChange={(values: any) => {
                    customerForm.setFieldValue("residenceAddress", values);
                }}
            />
        </div>
    );
};

export default ResidenceAddressForm;
