import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useMemo, useState } from "react";
import ProductAPI from "../../api/product/ProductAPI";
import { IProduct } from "../../interface/product";
import { IFieldSelect } from "../../interface/select";
import Messages from "../../languages/Messages";

export interface IProductSelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const ProductSelect = ({
    value = [],
    multiple,
    className,
    onChange,
    customQuery = {},
    ...selectProps
}: IProductSelect<IProduct>) => {
    const [productList, setProductList] = useState<IProduct[]>(
        isArray(value) ? value : [value]
    );

    const onChangeTextSearch = debounce((textSearch) => {
        if (isEmpty(textSearch)) {
            setProductList([]);
        } else {
            ProductAPI.list({
                page: 1,
                limit: 50,
                search: textSearch,
                sort: {
                    createdAt: -1,
                },
                ...customQuery,
            }).then((productRes: any) => {
                const selectedValue = isArray(value) ? value : [value];
                const filterProduct = map(
                    [...selectedValue, ...productRes],
                    (item) => ({
                        ...item,
                        id: item.id,
                        name: item.name,
                    })
                );
                const uniqProduct = uniqBy(filterProduct, (item) => item.id);
                setProductList(uniqProduct);
            });
        }
    }, 500);

    const onChangeValue = (values: any) => {
        if (multiple) {
            onChange(
                map(values, (item) =>
                    ObjectUtils.findItemFromId(productList, item)
                )
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(productList, values));
    };

    const productValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id ?? null;
    }, [value]);

    return (
        <Select
            onSearch={onChangeTextSearch}
            className={className}
            label={Messages.product}
            dataSource={productList}
            getLabel={(item) => item?.name}
            getKey={(item) => item?.id + item?.name}
            value={productValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            filterOption={false}
            {...selectProps}
        />
    );
};

export default ProductSelect;
