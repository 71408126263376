import { parse, stringify } from "query-string";
import { BrowserRouter } from "react-router-dom";
import { QueryParamProvider } from "use-query-params";
import { ReactRouter6Adapter } from "use-query-params/adapters/react-router-6";
import AppLayout from "./screen/AppLayout";
import InitComponent from "./screen/InitComponent";

function Root() {
    return (
        <BrowserRouter basename={process.env.PUBLIC_URL}>
            <QueryParamProvider
                adapter={ReactRouter6Adapter}
                options={{
                    searchStringToObject: parse,
                    objectToSearchString: stringify,
                }}
            >
                <InitComponent />
                <AppLayout />
            </QueryParamProvider>
        </BrowserRouter>
    );
}

export default Root;
