import { FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import BranchSelect from "../../../../components/branch/BranchSelect";
import { APP_WIDE_SCREEN_WIDTH } from "../../../../constant/app";

export interface ISelectBranchFormProps {
    customerForm: FormikProps<any>;
}

// @ts-ignore
export const SelectBranchFormSchema = Yup.object().shape<any>({
    branch: Yup.object().required("fieldRequired"),
});

const SelectBranchForm: React.FC<ISelectBranchFormProps> = ({
    customerForm,
}) => {
    return (
        <BranchSelect
            showLabel={false}
            value={customerForm?.values?.branch}
            onChange={(value) => {
                customerForm.setFieldValue("branch", value);
            }}
            error={customerForm?.errors?.branch}
        />
    );
};

export default SelectBranchForm;
