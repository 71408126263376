import { ObjectUtils, Select } from "d-react-components";
import { debounce, isArray, isEmpty, map, uniqBy } from "lodash";
import { useEffect, useMemo, useState } from "react";
import AddressAPI from "../../api/address/AddressAPI";
import { ICountry } from "../../interface/address";
import { IFieldSelect } from "../../interface/select";
import Messages from "../../languages/Messages";

interface ICountrySelect<T> extends IFieldSelect<T> {
    [key: string]: any;
}

const CountrySelect = ({
    value = [],
    multiple,
    className,
    onChange,
    ...selectProps
}: ICountrySelect<ICountry>) => {
    const [countryList, setCountryList] = useState<any[]>(
        isArray(value) ? value : [value]
    );

    useEffect(() => {
        AddressAPI.countryList({
            page: 1,
            limit: 10000,
        }).then((res: any) => {
            const countryRes = res ?? [];
            const selectedValue = isArray(value) ? value : [value];
            const filterCountry = map(
                [...selectedValue, ...countryRes],
                (item) => ({
                    id: item.id,
                    name: item.name,
                    primary: item.primary || false,
                })
            );
            const uniqCountry = uniqBy(filterCountry, (item) => item.id);
            setCountryList(uniqCountry);
        });
    }, []);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(countryList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(countryList, id));
    };

    const countryValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    return (
        <Select
            // onSearch={onChangeTextSearch}
            className={className}
            label={Messages.country}
            dataSource={countryList}
            getLabel={(item) => item?.name}
            value={countryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSearchAndSelect}
            showSearch
            {...selectProps}
        />
    );
};

export default CountrySelect;
