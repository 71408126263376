import { gql } from "@apollo/client";
import { F_PAGINATE } from "../app";

export const F_COUNTRY = gql`
    fragment F_COUNTRY on CountryDto {
        id
        name
        nationality
        translate {
            language
            name
            nationality
        }
    }
`;

export const F_PROVINCE = gql`
    fragment F_PROVINCE on ProvinceDto {
        id
        name
    }
`;

export const F_PROVINCES = gql`
    ${F_PROVINCE}
    ${F_PAGINATE}
    fragment F_PROVINCES on ProvincePaginateDto {
        data {
            ...F_PROVINCE
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_DISTRICT = gql`
    fragment F_DISTRICT on DistrictDto {
        id
        name
        translate {
            language
            name
        }
    }
`;

export const F_DISTRICTS = gql`
    ${F_DISTRICT}
    ${F_PAGINATE}
    fragment F_DISTRICTS on DistrictPaginateDto {
        data {
            ...F_DISTRICT
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
export const F_SUBDISTRICT = gql`
    fragment F_SUBDISTRICT on SubDistrictDto {
        id
        name
        translate {
            language
            name
        }
        postcode
    }
`;

export const F_SUBDISTRICTS = gql`
    ${F_SUBDISTRICT}
    ${F_PAGINATE}
    fragment F_SUBDISTRICTS on SubDistrictPaginateDto {
        data {
            ...F_SUBDISTRICT
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
export const F_COUNTRIES = gql`
    ${F_COUNTRY}
    ${F_PAGINATE}
    fragment F_COUNTRIES on CountryPaginateDto {
        data {
            ...F_COUNTRY
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;

export const F_CUSTOMER_ADDRESS = gql`
    ${F_COUNTRY}
    ${F_PROVINCE}
    ${F_DISTRICT}
    ${F_SUBDISTRICT}
    fragment F_CUSTOMER_ADDRESS on AddressDto {
        country {
            ...F_COUNTRY
        }
        province {
            ...F_PROVINCE
        }
        district {
            ...F_DISTRICT
        }
        subDistrict {
            ...F_SUBDISTRICT
        }
        postcode
        id
        firstName
        lastName
        nickName
        gender
        phone
        address
        latitude
        longitude
        typeOfPlace
        nameOfPlace
        isDefault
        remark
    }
`;

export const F_CUSTOMER_BILLING = gql`
    ${F_COUNTRY}
    fragment F_BILLING on BillingDto {
        country {
            ...F_COUNTRY
        }
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        id
        typeOfCustomer
        firstName
        lastName
        citizenId
        taxId
        companyName
        companyPhone
        email
        phone
        address
        isDefault
        sameServiceAddress
    }
`;
