export const TYPE_OF_PLACES = [
    { id: "private-house", label: "privateHouse" },
    { id: "condo-apartment", label: "condominium" },
    { id: "company-office", label: "companyOffice" },
];

export enum ShippingType {
    QUOTATION = "QUOTATION",
    ORDER = "ORDER",
}
