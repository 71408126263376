import React from "react";
import { ICountry, IProvince } from "../interface/address";
import { IOrder } from "../interface/order";
import { IPayment } from "../interface/payment";
import { IPaymentMethod } from "../interface/paymentMethod";
import { IQuotation } from "../interface/quotation";

export interface IAppState {
    paymentMethods?: IPaymentMethod[];
    provinceList?: IProvince[];
    countryList?: ICountry[];
    quotationDetail: IQuotation;
    setQuotationDetail: any;
    shippings?: any[];
    loadShippingList?: any;
    splitPayment?: any[];
    loadPaymentList?: any;
    branch?: any;
}

export const appStateDefault: IAppState = {
    paymentMethods: [],
    countryList: [],
    provinceList: [],
    quotationDetail: {} as any,
    setQuotationDetail: () => {},
};

export const QuotationContext = React.createContext(appStateDefault);

export interface IPaymentState {
    payment: IPayment;
}

export const paymentStateDefault: IPaymentState = {
    payment: {} as any,
};

export const PaymentDetailContext = React.createContext(paymentStateDefault);
