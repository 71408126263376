import { gql } from "@apollo/client";
import { F_PAGINATE } from "../app";

export const F_PRODUCT_ITEM = gql`
    fragment F_PRODUCT_ITEM on CustomerSearchProduct {
        id
        name
        productNo
        sku
        regularPrice
        salePrice
        isMedicine
        thumbnail
        gallery
        typeOfProduct
    }
`;

export const F_PRODUCTS = gql`
    ${F_PRODUCT_ITEM}
    ${F_PAGINATE}
    fragment F_PRODUCTS on SearchCustomerProductResponse {
        data {
            ...F_PRODUCT_ITEM
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
