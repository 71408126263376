import { Icon, ObjectUtils } from "d-react-components";
import { isEmpty, map } from "lodash";
import { IProduct } from "../../interface/product";
import Messages from "../../languages/Messages";
import Image from "../Image";

import ProductSelect, { IProductSelect } from "./ProductSelect";

interface IProductSolidItem {
    product: IProduct;
    onClickRemove: () => void;
}

export const ProductSolidItem = ({
    product,
    onClickRemove,
}: IProductSolidItem) => {
    return (
        <div className="w-100 bg-primary d-flex align-items-center pr-3 mt-2 border">
            <Image
                src={product?.thumbnail ?? product?.gallery?.[0]}
                className="image-square-medium "
            />
            <div className="flex-grow-1 flex-column text-white pl-2">
                <span className="block text-white font-weight-bold">
                    {product?.name}
                </span>
                <span className="block text-white text-sm opacity-75">
                    {`${Messages.sku}: ${product?.sku}`}
                </span>
            </div>
            <Icon
                name="delete"
                className="text-white cursor-pointer"
                onClick={onClickRemove}
            />
        </div>
    );
};

const ProductSolidSelect = ({
    className,
    ...selectProps
}: IProductSelect<IProduct>) => {
    const onRemoveInMultiple = (product: IProduct) => {
        const result = ObjectUtils.removeArrayById(
            selectProps?.value as any[],
            product?.id
        );
        selectProps?.onChange(result);
    };
    const renderProductSelected = () => {
        if (!selectProps?.value || isEmpty(selectProps?.value)) return <div />;
        if (selectProps?.multiple) {
            return map(selectProps?.value, (product) => (
                <ProductSolidItem
                    product={product}
                    onClickRemove={() => onRemoveInMultiple(product)}
                />
            ));
        }
        return (
            <ProductSolidItem
                product={selectProps?.value as IProduct}
                onClickRemove={() => selectProps?.onChange(null)}
            />
        );
    };

    return (
        <div className={className}>
            <ProductSelect {...selectProps} />
            {renderProductSelected()}
        </div>
    );
};

export default ProductSolidSelect;
