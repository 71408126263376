import {
    Button,
    Modal,
    Notifications,
    Progress,
    Select,
} from "d-react-components";
import { useFormik } from "formik";
import { findIndex, map } from "lodash";
import React, { useContext, useEffect, useMemo, useState } from "react";
import OrderAPI from "../../api/order/OrderAPI";
import QuotationAPI from "../../api/quotation/QuotationAPI";
import AddressFormInput from "../../components/address/AddressFormInput";
import InputTextForm from "../../components/InputTextForm";
import SectionHeader from "../../components/SectionHeader";
import { ShippingType } from "../../constant/address";
import { QuotationContext } from "../../context/quotation";
import {
    getFullAddress,
    ICountry,
    IServiceAddress,
    mapAddressToSer,
} from "../../interface/address";
import Messages from "../../languages/Messages";
import { AddressServiceSchema } from "../../schema/address";

interface IAddressUpdateModal {
    open: boolean;
    onClose: () => void;
    address: IServiceAddress;
}

interface IAddressDetail {
    address: IServiceAddress;
    index: number;
}

const AddressUpdateModal = ({
    open,
    onClose,
    address,
}: IAddressUpdateModal) => {
    const { quotationDetail, setQuotationDetail, shippings, loadShippingList, countryList } =
        useContext(QuotationContext);

    const defaultFormValue = {
        index: 1,
        ...address,
        // ...(shippings && shippings.length ? shippings[0] : {}),
    };
    const addressForm = useFormik<any>({
        initialValues: defaultFormValue as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AddressServiceSchema,
        onSubmit: (values: any) => {
            const input = mapAddressToSer(values);
            onUpdateShippingAddress(values?.id, input);
        },
    });

    const onUpdateShippingAddress = (addressId: string, input: any) => {
        Progress.show(
            {
                method: QuotationAPI.updateServiceAddress as any,
                params: [
                    quotationDetail?.id,
                    ShippingType.QUOTATION,
                    addressId,
                    input,
                ],
            },
            (orderRes) => {
                onClose();
                loadShippingList();
                Notifications.showSuccess(
                    Messages.updateShippingAddressSuccess
                );
            }
        );
    };

    const onChangeAddress = (id: string) => {
        const index = findIndex(shippings, (item) => item.id === id);
        onUpdateAddress(shippings?.[index], index);
    };

    const onUpdateAddress = (shipping: IServiceAddress, index: number) => {
        addressForm.setValues({ ...shipping, index });
    };

    const classNameInput = "col-12 col-sm-6 mt-3";
    const formValues = addressForm.values;
    const formErrors = addressForm.errors;
    const inputProps = {
        className: classNameInput,
        form: addressForm,
    };
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateShippingAddress}
            onSave={() => addressForm.handleSubmit()}
        >
            <div className="row">
                <Select
                    {...inputProps}
                    className="col-12 mt-3"
                    dataSource={shippings ?? []}
                    getLabel={getFullAddress}
                    value={formValues.id}
                    onChange={onChangeAddress}
                />
                <InputTextForm {...inputProps} keyData="firstName" />
                <InputTextForm {...inputProps} keyData="lastName" />
                <InputTextForm {...inputProps} keyData="nickName" />
                <InputTextForm {...inputProps} keyData="phone" />
                <AddressFormInput
                    formDataError={formErrors}
                    formDataValue={formValues}
                    countryList={countryList as ICountry[]}
                    onChange={(values: any) => {
                        addressForm.setValues({
                            ...addressForm.values,
                            ...values,
                        });
                    }}
                />
            </div>
        </Modal>
    );
};

const AddressDetail = ({ address, index }: IAddressDetail) => {
    const { quotationDetail } = useContext(QuotationContext);
    const { status } = quotationDetail ?? {};
    const { firstName, lastName, phone, nickName, typeOfPlace, nameOfPlace } =
        address;
    const [openEdit, setOpenEdit] = useState(false);
    const isHideEdit = useMemo(
        () =>
            !(
                quotationDetail?.hasOrder === false &&
                new Date(quotationDetail?.expired).getTime() >
                    new Date().getTime()
            ),
        [quotationDetail]
    );

    const fullAddress = getFullAddress(address);

    return (
        <div className="flex-column mt-3 pb-3 border-bottom">
            <div className="d-flex justify-content-between align-items-center">
                <label>{`${Messages.address} ${index}`}</label>
                <Button
                    onClick={() => setOpenEdit(true)}
                    variant="trans"
                    hidden={isHideEdit}
                >
                    {Messages.edit}
                </Button>
            </div>
            <div>
                {`${firstName} ${lastName}`} ({nickName})
            </div>
            <div>{phone}</div>
            <div>{fullAddress}</div>
            <div>
                {(Messages as any)[typeOfPlace]}: {nameOfPlace}
            </div>
            {openEdit && (
                <AddressUpdateModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                    address={address}
                />
            )}
        </div>
    );
};

const OrderShippingAddress = () => {
    const { shippings } = useContext(QuotationContext);

    return (
        <div className="card-container p-4 mt-3">
            <SectionHeader label={Messages.shippingInformation} hiddenEdit />
            <div className="flex-column">
                {map(shippings, (address, index) => (
                    <AddressDetail
                        address={address}
                        index={index + 1}
                        key={index}
                    />
                ))}
            </div>
        </div>
    );
};

export default OrderShippingAddress;
