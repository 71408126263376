/* eslint-disable consistent-return */
import { useEffect, useState } from "react";

function useCountDown(limit: number) {
    const [counter, setCounter] = useState(0);
    const [timeStartCounter, setTimeStartCounter] = useState(0);
    const disableTimer =
        !timeStartCounter || !(limit > 0) || !(timeStartCounter > 0);

    useEffect(() => {
        if (disableTimer) {
            return;
        }
        setCounter(limit);
    }, [timeStartCounter]);

    useEffect(() => {
        function intervalFunc() {
            setCounter((prev) => {
                if (!(prev > 1)) {
                    setTimeStartCounter(0);
                    clearInterval(interval);
                    setCounter(0);
                }
                return prev - 1;
            });
        }

        if (disableTimer) {
            return;
        }

        const interval = setInterval(() => {
            intervalFunc();
        }, 1000);
        return () => clearInterval(interval);
    }, [timeStartCounter]);

    return { counter, setTimeStartCounter };
}

export default useCountDown;
