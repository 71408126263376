import { gql } from "@apollo/client";
import API from "../API";
import { F_PRODUCTS } from "./ProductFragment";

export const getProducts = gql`
    ${F_PRODUCTS}
    query getProducts($input: SearchCustomerProductRequest!) {
        data: searchProductForCustomer(paginate: $input) {
            ...F_PRODUCTS
        }
    }
`;

const productsConverter = {
    fromServer: (res: any) => {
        const list = res?.data?.data?.data ?? [];
        return list;
    },
};

const ProductAPI = {
    list: async (input: any) =>
        API.withConverter(productsConverter).query({
            query: getProducts,
            variables: { input },
            fetchPolicy: "no-cache",
        }),
};

export default ProductAPI;
