export const CUSTOMER_TITLES = [
    { id: "Mr", label: "Mr" },
    { id: "Mrs", label: "Mrs" },
    { id: "Miss", label: "Miss" },
    { id: "Ms", label: "Ms" },
];

export const GENDERS = [
    { id: "MALE", label: "male" },
    { id: "FEMALE", label: "female" },
    { id: "NOT_SAY", label: "preferNotToSay" },
];

export const CUSTOMER_TYPES = [
    { id: "INDIVIDUAL", label: "individual" },
    { id: "BUSINESS", label: "business" },
];

export const RELIGIONS = [
    { id: "No religion", label: "noReligion" },
    { id: "Buddists", label: "duddists" },
    { id: "Christians", label: "christians" },
    { id: "Hindus", label: "hindus" },
    { id: "Muslims", label: "muslims" },
];

export const MARITAL_STATUS = [
    { id: "Single", label: "single" },
    { id: "Married", label: "married" },
    { id: "Divorced", label: "divorced" },
];

export const TYPE_OF_PLACES = [
    { id: "private-house", label: "privateHouse" },
    { id: "condo-apartment", label: "condominium" },
    { id: "company-office", label: "companyOffice" },
];

export const BLOOD_GROUPS = [
    { id: "A+", label: "A+" },
    { id: "A-", label: "A-" },
    { id: "B+", label: "B+" },
    { id: "B-", label: "B-" },
    { id: "O+", label: "O+" },
    { id: "O-", label: "O-" },
    { id: "AB+", label: "AB+" },
    { id: "AB-", label: "AB-" },
];

export const CUSTOMER_SOURCES = [
    { id: "WALK_IN", label: "walkIn" },
    { id: "FACEBOOK", label: "facebook" },
    { id: "INSTAGRAM", label: "instagram" },
    { id: "LINE", label: "line" },
    { id: "GOOGLE", label: "google" },
    { id: "OTHERS", label: "others" },
];
