import { map } from "lodash";
import moment from "moment";
import { IBillingAddress, IServiceAddress } from "./address";
import { ICustomer } from "./customer";
import { IPayment } from "./payment";

export enum DiscountType {
    Amount = "AMOUNT",
    Gift = "GIFT",
    Percentage = "PERCENTAGE",
}

export interface IOrderDiscount {
    discount: number;
    code: string;
}

export interface IOrderManualDiscount {
    discountType?: DiscountType;
    discountValue?: string;
    product?: IOrderProduct;
    isWholeCart?: boolean;
    doctorOrder?: any;
    total?: number;
    material?: any;
}

export interface IOrderFee {
    total: number;
    name: string;
}

export interface IOrderDiscountDisplay {
    total: number;
    subTitle: string;
    title: string;
    id: string;
    [key: string]: any;
}

export interface IOrderCreateState {
    discountList: IOrderDiscountDisplay[];
    subtotal: number;
    total: number;
    tax: number;
}

export interface IOrderProduct {
    id: string;
    name: string;
    sku: string;

    thumbnail: string;
    regularPrice: number;
    salePrice: number;
    quantity: number;
    gallery: string[];
    isChildren?: boolean;
}

export interface IOrderContact {
    firstName: string;
    lastName: string;
    email: string;
    phone: string;
}

export interface IOrderWorkSheet {
    id: string;
    workSheetNo: string;
    createdAt: string;
}

export interface IQuotationManualProduct {
    quantity: number;
    salePrice: number;
    name: string;
    remark: string;
    sku: string;
}

export interface IOrder {
    id: string;
    orderNo: string;
    // products: IOrderProduct[];
    subtotal: number;
    coupons: string[];
    discountVoucher: number;
    discountManual: number;

    tax: number;
    total: number;
    totalVat: number;
    note: string;
    customer: ICustomer;
    orderContact: IOrderContact;
    shippings: IServiceAddress[];
    billing: IBillingAddress;
    status: string;
    splitPayment: IPayment[];
    createdAt: string;
    expiredAt: string;
    vouchers: IOrderDiscount;
    manualDiscount: IOrderManualDiscount;
    fees: IOrderFee;
    isInvoice: boolean;
    workSheets: IOrderWorkSheet[];
    store?: {
        id: string;
        name: string;
    };

    contact: IOrderContact;
    fee: IOrderFee;
    expired: string;
    quotationNo: string;
    hasOrder: boolean;
    products: {
        quantity: number;
        regularPrice: number;
        salePrice: number;
        product: IOrderProduct;
    }[];
    manualProduct: IQuotationManualProduct[];
}

export const mapOrderContactToServer = (orderContact: any) => {
    const result = { ...orderContact };
    delete result.confirmEmail;
    delete result.confirmPhone;
    return result;
};

export const mapOrderPaymentProofToServer = (body: any) => {
    if (!body) return {};
    const { attachment, dateOfPayment, bankAccountId, method, ...rest } = body;
    return {
        ...rest,
        dateOfPayment: moment(dateOfPayment).toISOString(),
        attachments: map(attachment, (item) => item.fileUrl),
        bank: bankAccountId,
    };
};
