import { isEmpty } from "lodash";
import React, { useContext } from "react";
import InputTextForm from "../../components/InputTextForm";
import { AppointmentContext } from "../../context/appointment";

const AppointmentContact = () => {
    const { appointmentForm, appointmentRef } = useContext(AppointmentContext);
    const inputClassName = "col-6 mt-3";

    if (!isEmpty(appointmentRef)) return <div />;

    return (
        <>
            <InputTextForm
                className={inputClassName}
                keyData="firstName"
                prefix="contact"
                form={appointmentForm}
            />
            <InputTextForm
                className={inputClassName}
                keyData="lastName"
                prefix="contact"
                form={appointmentForm}
            />
            <InputTextForm
                className={inputClassName}
                keyData="phone"
                prefix="contact"
                form={appointmentForm}
            />
            <InputTextForm
                className={inputClassName}
                keyData="email"
                prefix="contact"
                form={appointmentForm}
            />
        </>
    );
};

export default AppointmentContact;
