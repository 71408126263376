/* eslint-disable import/no-extraneous-dependencies */
import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { find } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import { useMedia } from "react-use";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import { APP_WIDE_SCREEN_WIDTH } from "../../../constant/app";
import { ICustomer, mapCustomerToServer } from "../../../interface/customer";
import Messages from "../../../languages/Messages";
import { RegistrationContext } from "../context/RegistrationContext";
import REGISTRATION_FORM_DATA from "./RegistrationFormData";
import RegistrationFormTab from "./RegistrationFormTab";
import SubmitSuccess from "./shared/SubmitSuccess";

export interface IRegistrationFormProps {
    [key: string]: any;
    jobApp: any | undefined;
}

const mapPreFormCustomerSerToClient = (
    data: any
): ICustomer & { [key: string]: any } => {
    const {
        branch,
        title,
        firstNameEn,
        lastNameEn,
        firstNameTh,
        lastNameTh,
        nickname,
        birthDay,
        gender,
        citizenId,
        nationality,
        passportNo,
        passportExp,
        religion,
        maritalStatus,
        numberOfChild,
        phone,
        phoneCode,
        alternativePhone,
        email,
        lineId,
        instagram,
        facebook,
        familyInformation,
        emergencyContact,
        avatar,
        address = [],
    } = data;
    let residenceAddress: any = {};
    if (address && address?.length > 0) {
        const defaultAddress = find(address, (item) => item?.isDefault);
        if (defaultAddress) {
            residenceAddress = { ...defaultAddress };
        }
    }
    return {
        avatar,
        branch,
        title,
        firstNameEn,
        lastNameEn,
        firstNameTh,
        lastNameTh,
        nickname,
        birthDay,
        gender,
        citizenId,
        nationality,
        passportNo,
        passportExp,
        religion,
        maritalStatus,
        numberOfChild,
        phone,
        phoneCode,
        alternativePhone,
        email,
        lineId,
        instagram,
        facebook,
        familyInformation,
        emergencyContact,
        residenceAddress,
    };
};

const mapPreFormCustomerClientToSer = (
    data: Partial<ICustomer> & { [key: string]: any }
): any => {
    const { residenceAddress, address = [], ...rest } = data || {};
    const mappedCustomer = mapCustomerToServer(rest as ICustomer);
    if (residenceAddress?.country && residenceAddress?.province) {
        const {
            country,
            province,
            district,
            subDistrict,
            postcode,
            address,
            nameOfPlace,
            typeOfPlace,
        } = residenceAddress || {};
        const mappedResidenceAddress = {
            country: country?.id ?? null,
            province:
                typeof province === "string" ? province : province?.id ?? null,
            district:
                typeof district === "string" ? district : district?.id ?? null,
            subDistrict:
                typeof subDistrict === "string"
                    ? subDistrict
                    : subDistrict?.id ?? null,
            postcode,
            address,
            nameOfPlace,
            typeOfPlace,
        };
        Object.assign(mappedCustomer, {
            residenceAddress: mappedResidenceAddress,
        });
    } else {
        Object.assign(mappedCustomer, {
            residenceAddress: null,
        });
    }
    return mappedCustomer;
};

const TEST_VALUE = {
    title: "Mr",
    firstNameTh: "Yai3",
    lastNameTh: "Kot3",
    firstNameEn: "Rattana3",
    lastNameEn: "kot3",
    nickname: "Yai3",
    gender: "MALE",
    citizenId: "2222222222233",
};

const RegistrationForm: React.FC<IRegistrationFormProps> = ({ jobApp }) => {
    const {
        authState,
        setAuthState,
        customerId = "",
        isUpdateInfo,
        formValueState,
        setFormValueState,
    } = useContext(RegistrationContext);
    const isWide = useMedia(`(min-width: ${APP_WIDE_SCREEN_WIDTH}px)`);
    const [activeTab, setActiveTab] = useState<any>(REGISTRATION_FORM_DATA[0]);
    const activeIndex = useMemo(
        () =>
            REGISTRATION_FORM_DATA.findIndex(
                (item) => item?.id === activeTab.id
            ),
        [activeTab]
    );
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const refCus = useRef<string>(null);
    const isFirstOne = activeIndex === 0;
    const isLastOne = activeIndex === REGISTRATION_FORM_DATA.length - 1;
    const nextStep = REGISTRATION_FORM_DATA[activeIndex + 1];
    const prevStep = REGISTRATION_FORM_DATA[activeIndex - 1];
    const registrationForm = useFormik<Partial<ICustomer> & { files: any }>({
        initialValues: {
            ...formValueState,
            // ...TEST_VALUE,
        } as any,
        onSubmit: () => {
            handleOnClickNext();
        },
        validateOnChange: false,
        validationSchema: activeTab.schema,
        enableReinitialize: true,
    });

    const { handleSubmit, errors, values, setValues, setFieldValue } =
        registrationForm;
    const { accessToken = "" } = authState;

    console.log(
        "🚀 >>>>>> file: RegistrationForm.tsx:138 >>>>>> values:",
        values
    );
    console.log(
        "🚀 >>>>>> file: RegistrationForm.tsx:126 >>>>>> errors:",
        errors
    );

    useEffect(() => {
        if (isUpdateInfo) {
            loadCustomerPreformData();
        }
    }, [isUpdateInfo]);

    useEffect(() => {
        if (!isUpdateInfo && authState?.accessToken) {
            reCheckAccessToken(authState?.accessToken);
        }
    }, [isUpdateInfo]);

    const loadCustomerPreformData = async () => {
        try {
            const res = await CustomerAPI.loadPreFormData({
                customerId,
                accessToken,
            });
            const customerSer = res?.data?.data?.data ?? {};
            const customer = mapPreFormCustomerSerToClient(customerSer);
            setValues({ ...values, ...customer, ...formValueState });
        } catch (error: any) {
            Notifications.showError(error?.message ?? "");
            setAuthState({ isAllowed: false, accessToken: "" });
        }
    };

    const reCheckAccessToken = async (accessToken: string) => {
        try {
            const res = await CustomerAPI.verifyPreFormAccessToken(accessToken);
            const { isValidCode } = res?.data?.data ?? {};
            if (isValidCode) {
                return;
            }
            setAuthState({ isAllowed: false, accessToken: "" });
        } catch (error: any) {
            Notifications.showError(error?.message ?? "");
            setAuthState({ isAllowed: false, accessToken: "" });
        }
    };

    const handleOnClickNext = () => {
        const currentStep = REGISTRATION_FORM_DATA[activeIndex];
        if (!isLastOne) {
            setActiveTab(nextStep);
            //@ts-ignore
            setFormValueState((v: any) => ({ ...v, ...values }));
        }
    };

    const finishForm = () => {
        const valid = handleSubmit();
        const customerPayload = mapPreFormCustomerClientToSer(values);
        const payload = { input: customerPayload, accessToken };
        if (isUpdateInfo) {
            Object.assign(payload, { customerId });
        }
        const api = isUpdateInfo
            ? CustomerAPI.createRequestUpdateInfo
            : CustomerAPI.createSelfRegistration;

        return Progress.show(
            { method: api, params: [payload] },
            (res: any) => {
                const ref = res?.data?.data ?? null;
                if (ref) {
                    //@ts-ignore
                    refCus.current = ref;
                }
                setShowSuccess(true);
                setTimeout(() => {
                    setAuthState({ isAllowed: false, accessToken: "" });
                }, 5000);
            },
            (error: any) => {
                Notifications.showError(error?.message ?? "");
                // setAuthState({ isAllowed: false, accessToken: "" });
            }
        );
    };

    const renderButton = () => {
        return (
            <div className="w-100 flex-center-y justify-content-between my-4">
                {isFirstOne ? (
                    <div />
                ) : (
                    <Button
                        content={
                            !isWide
                                ? `${Messages.step} ${activeIndex}`
                                : `${Messages.step} ${activeIndex}: ${
                                      Messages[
                                          prevStep.label as keyof typeof Messages
                                      ]
                                  }`
                        }
                        iconName="chevron_left"
                        onClick={() => setActiveTab(prevStep)}
                    />
                )}
                {isLastOne ? (
                    <div />
                ) : (
                    <Button
                        content={
                            !isWide
                                ? `${Messages.step} ${activeIndex + 2}`
                                : `${Messages.step} ${activeIndex + 2}: ${
                                      Messages[
                                          nextStep.label as keyof typeof Messages
                                      ]
                                  }`
                        }
                        suffixIcon="chevron_right"
                        onClick={handleSubmit as any}
                    />
                )}
                {isLastOne && (
                    <Button content={Messages.submit} onClick={finishForm} />
                )}
            </div>
        );
    };

    return (
        <div className="ml-auto mr-auto md:p-4 min-h-screen">
            {!showSuccess ? (
                <div className="md:grid md:grid-flow-col gap-5">
                    <RegistrationFormTab
                        className=""
                        activeIndex={activeIndex}
                    />
                    <div className="col-span-5">
                        <div className="md:shadow-lg md:bg-white md:p-8">
                            <h5 className="my-2">
                                {
                                    Messages[
                                        activeTab.label as keyof typeof Messages
                                    ]
                                }
                            </h5>
                            {React.cloneElement(
                                (activeTab.component as any) || "N/A",
                                {
                                    customerForm: registrationForm,
                                }
                            )}
                        </div>
                        {renderButton()}
                    </div>
                </div>
            ) : (
                <SubmitSuccess refCode={refCus.current} />
            )}
        </div>
    );
};

export default RegistrationForm;
