import { ObjectUtils, Select } from "d-react-components";
import { isArray, isEmpty, map } from "lodash";
import { useEffect, useMemo, useState } from "react";
import BranchAPI from "../../api/branch/BranchAPI";
import { IBranch } from "../../interface/branch";
import { IFieldSelect } from "../../interface/select";
import Messages from "../../languages/Messages";

export interface IBranchSelect<T> extends IFieldSelect<T> {
    showLabel?: boolean;
}

const BranchSelect = ({
    value = [],
    multiple,
    className,
    showLabel = true,
    onChange,
    ...selectProps
}: IBranchSelect<IBranch>) => {
    const [branchList, setBranchList] = useState<IBranch[]>(
        isArray(value) ? value : [value]
    );

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(branchList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(branchList, id));
    };

    const branchValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);

    useEffect(() => {
        BranchAPI.list({ page: 1, limit: 100, search: "" }).then((res) => {
            setBranchList(res?.data?.data?.data ?? []);
        });
    }, []);

    return (
        <Select
            className={className}
            label={showLabel ? Messages.branch : undefined}
            dataSource={branchList}
            getLabel={(item) => item?.name}
            value={branchValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.pleaseSelect}
            showSearch
            {...selectProps}
        />
    );
};

export default BranchSelect;
