import { Route, Routes } from "react-router-dom";
import Quotation from "./quotation/Quotation";
import Order from "./order/Order";
import Path from "./Path";
import Appointment from "./appointment/Appointment";
import Registration from "./registration/Registration";
import HealthAssessment from "./health-assessment/HealthAssessment";

const RoutesLayout = () => {
    return (
        <div className="overflow-auto scroll-hide-indicator">
            <Routes>
                <Route path={Path.ROOT} element={<></>} />
                <Route path={Path.QUOTATION} element={<Quotation />} />
                <Route path={Path.ORDER} element={<Order />} />
                <Route path={Path.APPOINTMENT} element={<Appointment />} />
                <Route path={Path.QUOTATION} element={<Quotation />} />
                <Route path={Path.REGISTER} element={<Registration />} />
                <Route path={Path.UPDATE_INFO} element={<Registration updateInfo />} />
                <Route
                    path={Path.HEALTH_ASSESSMENT}
                    element={<HealthAssessment />}
                />
            </Routes>
        </div>
    );
};

export default RoutesLayout;
