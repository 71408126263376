import { map } from "lodash";
import { getSecondFromTime } from "../api/util/time";
import { ICustomer } from "./customer";
import { IOrder } from "./order";
import { IQuotation } from "./quotation";

export interface IAppointment {
    id: string;
    updatedAt: string;
    createdAt: string;
    day: string;
    startDate: string;
    endDate: string;
    scheduleDate: string;
    schedule: {
        start: number;
        end: number;
    };
    appointmentNo: string;
    status: string;
    remark: string;
    // branch: IBranch;
    // services: IProduct[];
    customer: ICustomer;
    contact: {
        firstName: string;
        lastName: string;
        email: string;
        phone: string;
    };
    order: IOrder;
    quotation: IQuotation;
}

export const mapAppointmentToServer = (input: any) => {
    return {
        schedule: {
            start: getSecondFromTime(input.time[0]),
            end: getSecondFromTime(input.time[1]),
        },
        scheduleDate: input?.date,
        branch: input?.branch?.id,
        services: map(input?.products, (item) => item.id),
        remark: input?.remark,
        ref: input?.ref,
        refId: input?.refId,
        contact: input?.contact,
    };
};
