/* eslint-disable class-methods-use-this */
import {
    DialogComponent,
    DialogManager,
    Progress,
    ProgressComponent,
    UrlUtils,
} from "d-react-components";
import React, { Component } from "react";
import { LANGUAGE_DEFAULT } from "../constant/app";
import Messages from "../languages/Messages";

class InitComponent extends Component<any> {
    progressRef: any;

    dialogRef: any;

    constructor(props: any) {
        super(props);
        this.onInitialLanguage();
        this.progressRef = null;
        this.dialogRef = null;
    }

    componentDidMount() {
        Progress.initialProgress(this.progressRef);
        DialogManager.initialDialog(this.dialogRef);
    }

    onInitialLanguage = () => {
        const { locale = LANGUAGE_DEFAULT } = UrlUtils.getQuery();
        console.log("locale", locale);
        Messages.setLanguage(locale as string);
    };

    render() {
        return (
            <div>
                <ProgressComponent
                    ref={(ref) => {
                        this.progressRef = ref;
                    }}
                />
                <DialogComponent
                    ref={(ref) => {
                        this.dialogRef = ref;
                    }}
                />
            </div>
        );
    }
}

export default InitComponent;
