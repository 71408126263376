import { Button, Modal, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React, { useContext, useMemo, useState } from "react";
import InputTextForm from "../../components/InputTextForm";
import { QuotationContext } from "../../context/quotation";
import { mapOrderContactToServer } from "../../interface/order";
import Messages from "../../languages/Messages";
import { OrderContactSchema } from "../../schema/order";
import SectionHeader from "../../components/SectionHeader";
import QuotationAPI from "../../api/quotation/QuotationAPI";

interface IOrderContactUpdateModal {
    open: boolean;
    onClose: () => void;
}

const OrderContactUpdateModal = ({
    open,
    onClose,
}: IOrderContactUpdateModal) => {
    const { quotationDetail, setQuotationDetail } =
        useContext(QuotationContext);
    const { contact } = quotationDetail ?? {};

    const contactValueDefault = {
        ...(contact ?? {}),
        confirmEmail: contact?.email,
        confirmPhone: contact?.phone,
    };

    const validateContact = (values: any) => {
        const { confirmEmail, email, confirmPhone, phone } = values;
        const errors = {} as any;
        if (confirmEmail !== email) {
            errors.confirmEmail = Messages.emailNotMatch;
        }
        if (confirmPhone !== phone) {
            errors.confirmPhone = Messages.phoneNotMatch;
        }

        return errors;
    };

    const contactForm = useFormik<any>({
        initialValues: contactValueDefault as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: OrderContactSchema,
        validate: validateContact,
        onSubmit: (values: any) => {
            const input = mapOrderContactToServer(values);
            onUpdateContact(input);
        },
    });

    const onUpdateContact = (input: any) => {
        Progress.show(
            {
                method: QuotationAPI.updateContact,
                params: [quotationDetail?.id, { contact: input }],
            },
            (orderRes: any) => {
                onClose();
                setQuotationDetail(orderRes);
                Notifications.showSuccess(Messages.updateOrderContactSuccess);
            }
        );
    };

    const classNameInput = "col-12 col-sm-6 mt-3";

    const renderInput = (keyData: string) => (
        <InputTextForm
            form={contactForm}
            className={classNameInput}
            keyData={keyData}
        />
    );
    return (
        <Modal
            open={open}
            onClose={onClose}
            title={Messages.updateContact}
            onSave={() => contactForm.handleSubmit()}
        >
            <div className="row">
                {renderInput("firstName")}
                {renderInput("lastName")}
                {renderInput("email")}
                {renderInput("confirmEmail")}
                {renderInput("phone")}
                {renderInput("confirmPhone")}
            </div>
        </Modal>
    );
};

const OrderContact = () => {
    const { quotationDetail } = useContext(QuotationContext);
    const { status, contact } = quotationDetail ?? {};
    const [openEdit, setOpenEdit] = useState(false);

    const isHideEdit = useMemo(
        () =>
            !(
                quotationDetail?.hasOrder === false &&
                new Date(quotationDetail?.expired).getTime() >
                    new Date().getTime()
            ),
        [quotationDetail]
    );

    return (
        <div className="card-container p-4 mt-3">
            <SectionHeader
                label={Messages.contactInformation}
                hiddenEdit={isHideEdit}
                onClickEdit={() => setOpenEdit(true)}
            />
            <div className="mt-3 flex-column">
                <text>{`${contact?.firstName} ${contact?.lastName}`}</text>
                <text>{contact?.phone}</text>
                <text>{contact?.email}</text>
            </div>
            {openEdit && (
                <OrderContactUpdateModal
                    open={openEdit}
                    onClose={() => setOpenEdit(false)}
                />
            )}
        </div>
    );
};

export default OrderContact;
