import { useFirstTime } from "d-react-components";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import { loadFromLocal, saveToLocal } from "../../data/local-storage";
import { ICustomer } from "../../interface/customer";
import { RegistrationContext } from "./context/RegistrationContext";
import RegistrationForm from "./form/RegistrationForm";
import RegistrationVerify from "./verify/RegistrationVerify";

const NEW_REGISTRATION_DATA_KEY = "NEW_REGISTRATION_DATA_KEY";
const UPDATE_INFO_DATA_KEY = "UPDATE_INFO_DATA_KEY";

export interface IRegistrationProps {
    updateInfo?: boolean;
}

export interface IRegistrationAuthState {
    isAllowed?: boolean;
    accessToken?: string;
    code?: string;
}

export interface IRegistrationFormValueState extends Partial<ICustomer> {
    isVerifiedPhone?: boolean;
}

const Registration: React.FC<IRegistrationProps> = ({ updateInfo }) => {
    const { customerId } = useParams();
    const isFirstTime = useFirstTime();
    const isUpdateInfo =
        !!updateInfo && !!customerId && customerId?.length === 24;
    const storageKey = isUpdateInfo
        ? UPDATE_INFO_DATA_KEY
        : NEW_REGISTRATION_DATA_KEY;
    const [authState, setAuthState] = useState<IRegistrationAuthState>({
        isAllowed: false,
        ...(loadFromLocal(storageKey)?.authState ?? {}),
    });

    const [formValueState, setFormValueState] =
        useState<IRegistrationFormValueState>({
            ...(loadFromLocal(storageKey)?.formValueState ?? {}),
        });

    useEffect(() => {
        if (isFirstTime && !isUpdateInfo && authState.code) {
            reCheckNewRegistrationCode(authState.code);
        }
    }, []);

    useEffect(() => {
        if (isFirstTime) {
            return;
        }
        const savedData = loadFromLocal(storageKey);
        saveToLocal(storageKey, { ...(savedData || {}), authState });
    }, [authState?.isAllowed, authState?.accessToken]);

    useEffect(() => {
        if (isFirstTime) {
            return;
        }
        const savedData = loadFromLocal(storageKey);
        saveToLocal(storageKey, { ...(savedData || {}), formValueState });
    }, [formValueState]);

    const reCheckNewRegistrationCode = (code: string) => {};

    return (
        <RegistrationContext.Provider
            value={{
                isUpdateInfo,
                customerId,
                authState,
                formValueState,
                setAuthState,
                setFormValueState,
            }}
        >
            {!authState?.isAllowed ? (
                <RegistrationVerify />
            ) : (
                <RegistrationForm jobApp={{}} />
            )}
        </RegistrationContext.Provider>
    );
};

export default Registration;
