/* eslint-disable consistent-return */
/* eslint-disable jsx-a11y/label-has-associated-control */

import { Button } from "d-react-components";
import { useFormik } from "formik";
import ClassNames from "classnames";
import React from "react";
import AuthCode from "react-auth-code-input";
import * as Yup from "yup";
import "./OtpFormView.scss";
import Messages from "../languages/Messages";
import useCountDown from "../common/hooks/useCountDown";

export const AuthOtpSchema = Yup.object().shape({
    code: Yup.string().length(6, "6 number required!").required("Required"),
});

export interface IOtpVerificationViewProps {
    onSubmitHandler?: (code: string) => any;
    phone?: string;
    onChangeCode?: any;
}

const OtpVerificationView: React.FC<IOtpVerificationViewProps> = ({
    onSubmitHandler,
    phone,
    onChangeCode,
}) => {
    const { counter, setTimeStartCounter } = useCountDown(5);
    const formik = useFormik({
        initialValues: {
            code: "",
        },
        validationSchema: AuthOtpSchema,
        onSubmit: (values) => {
            onSubmitHandler && onSubmitHandler(values?.code);
        },
    });

    const onRequestOtp = async () => {
        if (!phone) {
            return true;
        }
        // const res = await AuthAPI.requestOtp({ phone });
        // const token = res?.data?.data?.token;
        // if (token) {
        //     verifyForm.setFieldValue("otpToken", token);
        // }
    };

    return (
        <form
            onSubmit={formik.handleSubmit}
            className="mt-3 flex-column flex-center"
        >
            <div className="text-center">
                <div className={ClassNames("mb-5")}>
                    <div
                        className={ClassNames(
                            "text mt-3 text-black text-center"
                        )}
                    >
                        {Messages.inOrderToStartRegistration}
                        <span
                            className={ClassNames(
                                "text mt-1 text-red-400 text-center"
                            )}
                        >
                            {`  (+66) ${phone || "N/A"}`}
                        </span>
                    </div>
                </div>
                <AuthCode
                    onChange={(code) => {
                        formik.setFieldValue("code", code);
                        onChangeCode && onChangeCode(code);
                    }}
                    containerClassName="auth-code__container mt-3"
                    inputClassName="auth-code__input"
                />
                <div>
                    <div
                        className={ClassNames(
                            "mt-3 text-placeholder text-center"
                        )}
                    >
                        {Messages.didNotReceiveTheCode}
                    </div>
                    <div className="w-full flex justify-center">
                        <Button
                            variant="trans"
                            onClick={() => {
                                setTimeStartCounter(new Date().valueOf());
                                onRequestOtp();
                            }}
                            disabled={counter > 0}
                            className={ClassNames(
                                "flex-center-y justify-content-center"
                            )}
                        >
                            <div
                                className={ClassNames("text-black text-center")}
                            >
                                {Messages.resendNow}
                            </div>
                            {counter && counter > 0 ? (
                                <div
                                    className={ClassNames(
                                        "text-black text-center"
                                    )}
                                >{` (${counter})`}</div>
                            ) : null}
                        </Button>
                    </div>
                </div>
            </div>
        </form>
    );
};

export default OtpVerificationView;
