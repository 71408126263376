import classNames from "classnames";
import { InputText as Input, ObjectUtils, Select } from "d-react-components";
import { debounce, map } from "lodash";
import React, { useContext, useEffect, useMemo, useRef, useState } from "react";
import AddressAPI from "../../api/address/AddressAPI";
import { TYPE_OF_PLACES } from "../../constant/address";
import { QuotationContext } from "../../context/quotation";
import {
    IAddressFormShow,
    ICountry,
    IDistrict,
    IProvince,
    ISubdistrict,
} from "../../interface/address";
import Messages from "../../languages/Messages";

interface IAddressFormInput {
    formDataValue: any;
    formDataError: any;
    show?: IAddressFormShow;
    onChange: any;
    disabled?: boolean;
    countryList: ICountry[];
    classNameInput?: string;
}

const DEFAULT_SHOW = {
    typeOfPlace: true,
    remark: false,
    map: false,
    country: true,
    address: true,
};

const AddressFormInput = ({
    formDataValue,
    formDataError,
    show = {},
    onChange,
    disabled,
    countryList,
    classNameInput,
}: IAddressFormInput) => {
    const showField = { ...DEFAULT_SHOW, ...show };
    const [listProvince, setListProvince] = useState([]);
    const [listCity, setListCity] = useState([]);
    const [listSubdistrict, setListSubdistrict] = useState([]);
    const [listPostCode, setListPostCode] = useState<string[]>([]);

    const [location, setLocation] = useState<any>({
        latitude: formDataValue.latitude,
        longitude: formDataValue.longitude,
    });
    const isPrimaryCountry = useMemo(
        () => !!formDataValue?.country?.primary,
        [formDataValue?.country]
    );

    useEffect(() => {
        if (showField.map) {
            onChangeValue({ latitude: location.latitude });
        }
    }, [location]);

    useEffect(() => {
        if (location.longitude && showField.map) {
            onChangeValue({ longitude: location.longitude });
        }
    }, [formDataValue.latitude]);

    useEffect(() => {
        if (!formDataValue?.country?.id || !isPrimaryCountry) {
            return;
        }
        AddressAPI.provinceList(formDataValue?.country?.id, {
            page: 1,
            limit: 10000,
        }).then((resp) => {
            setListProvince(resp);
        });
    }, [formDataValue?.country, isPrimaryCountry]);

    useEffect(() => {
        if (!formDataValue?.province?.id) {
            return;
        }
        AddressAPI.districtList(formDataValue?.province?.id, {
            page: 1,
            limit: 1000,
        }).then(setListCity);
    }, [formDataValue?.province]);

    useEffect(() => {
        if (!formDataValue?.district?.id) {
            return;
        }
        AddressAPI.subDistrictList(formDataValue?.district?.id, {
            page: 1,
            limit: 1000,
        }).then(setListSubdistrict);
    }, [formDataValue?.district]);

    useEffect(() => {
        if (!formDataValue?.subDistrict || listSubdistrict.length === 0) {
            return;
        }
        const subDistrict: any = listSubdistrict.find(
            (item: ISubdistrict) => item.id === formDataValue?.subDistrict?.id
        );
        setListPostCode(subDistrict?.postcode ?? []);
    }, [formDataValue?.subDistrict, listSubdistrict]);

    const isFirstTime = useRef(true);
    useEffect(() => {
        if (
            (isFirstTime.current &&
                formDataValue?.latitude &&
                formDataValue?.longitude) ||
            !showField.map
        ) {
            isFirstTime.current = false;
            return;
        }
        loadLocation();
    }, [
        formDataValue?.province?.id,
        formDataValue?.district?.id,
        formDataValue?.subDistrict?.id,
    ]);

    const loadLocation = debounce(() => {
        // const addressList = [
        //     formDataValue?.subDistrict?.name,
        //     formDataValue?.district?.name,
        //     formDataValue?.province?.name,
        // ].filter((item) => !!item);
        // const fulAddress = join(addressList, ",");
        // if (isEmpty(fulAddress)) {
        //     return;
        // }
        // API.getLocationFromAddress(fulAddress).then((res) => {
        //     const locationResult = res?.data?.results?.[0]?.geometry?.location;
        //     if (locationResult) {
        //         setLocation({
        //             latitude: locationResult.lat,
        //             longitude: locationResult.lng,
        //         });
        //     }
        // });
    }, 1000);

    const onChangeValue = (values = {}) => {
        onChange({ ...formDataValue, ...values });
    };

    const onChangeCountry = (id: string) => {
        const countryValue = ObjectUtils.findItemFromId(countryList, id);
        onChangeValue({
            country: countryValue,
            province: null,
            district: null,
            subDistrict: null,
            postcode: null,
        });
    };

    const onChangeProvince = (id: string) => {
        const provinceValue = ObjectUtils.findItemFromId(listProvince, id);
        onChangeValue({
            ...formDataValue,
            province: provinceValue,
            district: null,
            subDistrict: null,
            postcode: null,
        });
    };

    const onChangeDistrict = (id: string) => {
        const districtValue = ObjectUtils.findItemFromId(listCity, id);
        onChangeValue({
            ...formDataValue,
            district: districtValue,
            subDistrict: null,
            postcode: null,
        });
    };

    const onChangeSubDistrict = (id: string) => {
        const subDistrictValue = ObjectUtils.findItemFromId(
            listSubdistrict,
            id
        );
        onChangeValue({
            ...formDataValue,
            subDistrict: subDistrictValue,
            postcode: null,
        });
    };

    // const renderMapSelect = () => {
    //     if (!location?.latitude || !location?.longitude) {
    //         return <div />;
    //     }
    //     return (
    //         <div className="col-12 mt-3">
    //             <AddressMapSelect
    //                 location={location}
    //                 onChange={(location) => {
    //                     setLocation(location);
    //                 }}
    //             />
    //         </div>
    //     );
    // };

    const classNameInputField = classNames(
        "col-12 col-sm-6 mt-3",
        classNameInput
    );

    const inputProps = {
        className: classNameInputField,
        disabled,
    };

    const renderProvinceInput = () => {
        if (!isPrimaryCountry) {
            return (
                <Input
                    {...inputProps}
                    label={Messages.province}
                    placeholder={Messages.province}
                    onChange={(event: any) =>
                        onChangeValue({ province: event?.target.value })
                    }
                    value={formDataValue?.province}
                    error={formDataError?.province}
                    disabled={!formDataValue?.country}
                />
            );
        }
        return (
            <Select
                {...inputProps}
                label={Messages.province}
                placeholder={Messages.province}
                dataSource={listProvince}
                onChange={onChangeProvince}
                value={formDataValue?.province?.id}
                error={formDataError?.province}
                getLabel={(item: IProvince) => item.name}
                required
            />
        );
    };

    const renderDistrictInput = () => {
        if (!isPrimaryCountry) {
            return (
                <Input
                    {...inputProps}
                    label={Messages.district}
                    placeholder={Messages.district}
                    onChange={(event: any) =>
                        onChangeValue({ district: event?.target.value })
                    }
                    value={formDataValue?.district}
                    error={formDataError?.district}
                    disabled={!formDataValue?.province}
                />
            );
        }
        return (
            <Select
                {...inputProps}
                label={Messages.district}
                placeholder={Messages.district}
                dataSource={listCity}
                onChange={onChangeDistrict}
                value={formDataValue?.district?.id}
                error={formDataError?.district}
                disabled={listCity.length === 0 || disabled}
                getLabel={(item: IDistrict) => item.name}
                required
            />
        );
    };

    const renderSubDistrictInput = () => {
        if (!isPrimaryCountry) {
            return (
                <Input
                    {...inputProps}
                    label={Messages.subDistrict}
                    placeholder={Messages.subDistrict}
                    onChange={(event: any) =>
                        onChangeValue({ subDistrict: event?.target.value })
                    }
                    value={formDataValue?.subDistrict}
                    error={formDataError?.subDistrict}
                    disabled={!formDataValue?.district}
                />
            );
        }
        return (
            <Select
                {...inputProps}
                label={Messages.subDistrict}
                placeholder={Messages.subDistrict}
                dataSource={listSubdistrict}
                onChange={onChangeSubDistrict}
                value={formDataValue?.subDistrict?.id}
                error={formDataError?.subDistrict}
                disabled={listSubdistrict.length === 0 || disabled}
                getLabel={(item: ISubdistrict) => item.name}
                required
            />
        );
    };

    const renderPostcodeInput = () => {
        if (!isPrimaryCountry) {
            return (
                <Input
                    {...inputProps}
                    label={Messages.postcode}
                    placeholder={Messages.postcode}
                    onChange={(event: any) =>
                        onChangeValue({ postcode: event?.target.value })
                    }
                    value={formDataValue?.postcode}
                    error={formDataError?.postcode}
                    disabled={!formDataValue?.subDistrict}
                />
            );
        }
        return (
            <Select
                {...inputProps}
                label={Messages.postcode}
                placeholder={Messages.postcode}
                dataSource={listPostCode}
                onChange={(value: any) => onChangeValue({ postcode: value })}
                value={formDataValue?.postcode}
                error={formDataError?.postcode}
                disabled={listPostCode.length === 0 || disabled}
                getLabel={(item: string) => item}
                getValue={(item: string) => item}
                required
            />
        );
    };

    return (
        <>
            <Select
                {...inputProps}
                hidden={!showField.country}
                label={Messages.country}
                placeholder={Messages.country}
                dataSource={countryList}
                onChange={onChangeCountry}
                value={formDataValue?.country?.id}
                error={formDataError?.country}
                getLabel={(item: ICountry) => item.name}
                required
                showSearch
            />
            {renderProvinceInput()}
            {renderDistrictInput()}
            {renderSubDistrictInput()}
            {renderPostcodeInput()}

            <Input
                {...inputProps}
                hidden={!showField.address}
                label={Messages.address}
                placeholder={Messages.address}
                onChange={(event: any) =>
                    onChangeValue({ address: event?.target.value })
                }
                value={formDataValue?.address}
                error={formDataError?.address}
                required
            />
            <Select
                {...inputProps}
                label={Messages.typeOfPlace}
                placeholder={Messages.typeOfPlace}
                dataSource={map(TYPE_OF_PLACES, (item) => ({
                    ...item,
                    label: (Messages as any)[item.label],
                }))}
                onChange={(value: any) => onChangeValue({ typeOfPlace: value })}
                value={formDataValue?.typeOfPlace}
                error={formDataError?.typeOfPlace}
                hidden={!showField.typeOfPlace}
                required
            />
            <Input
                {...inputProps}
                label={Messages.nameOfPlace}
                placeholder={Messages.nameOfPlace}
                onChange={(event: any) =>
                    onChangeValue({ nameOfPlace: event?.target.value })
                }
                value={formDataValue?.nameOfPlace}
                error={formDataError?.nameOfPlace}
                hidden={!showField.typeOfPlace}
            />
            {/* {showField.map && renderMapSelect()} */}
            {/* <div className="col-12" hidden={!showField.remark}>
                <Input
                    label={Messages.remark}
                    onChange={(event: any) =>
                        onChangeValue({ remark: event?.target.value })
                    }
                    value={formDataValue?.remark}
                    error={formDataError?.remark}
                    multiple
                />
            </div> */}
        </>
    );
};

export default AddressFormInput;
