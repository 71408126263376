import { isEmpty, map } from "lodash";
import React from "react";
import { getFullAddress } from "../../interface/address";
import { IBranch } from "../../interface/branch";
import Messages from "../../languages/Messages";

import BranchSelect, { IBranchSelect } from "./BranchSelect";

interface IBranchSolidItem {
    branch: IBranch;
}

export const BranchSolidItem = ({ branch }: IBranchSolidItem) => {
    const { name, code, address } = branch;
    return (
        <div className="w-100 mt-3 bg-primary d-flex">
            <div className="p-2 flex-column">
                <span className="block text-white font-weight-bold">
                    {name}
                </span>
                <span className="block text-white opacity-75">{`${Messages.code}: ${code}`}</span>
                <span className="block text-white opacity-75">{`${
                    Messages.address
                }: ${getFullAddress(address)}`}</span>
            </div>
        </div>
    );
};

const BranchSolidSelect = ({
    className,
    ...selectProps
}: IBranchSelect<IBranch>) => {
    const renderBranchSelected = () => {
        if (!selectProps?.value || isEmpty(selectProps?.value)) return <div />;
        if (selectProps?.multiple) {
            return map(selectProps?.value, (branch) => (
                <BranchSolidItem branch={branch} />
            ));
        }
        return <BranchSolidItem branch={selectProps?.value as IBranch} />;
    };

    return (
        <div className={className}>
            <BranchSelect {...selectProps} />
            {renderBranchSelected()}
        </div>
    );
};

export default BranchSolidSelect;
