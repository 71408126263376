import { gql } from "@apollo/client";
import API from "../API";

export default {
    addressList: (refId: string) => {
        return API.query({
            query: gql`
                query listAllShippingForCustomer($refId: String!) {
                    listAllShippingForCustomer(refId: $refId) {
                        data {
                            id
                            firstName
                            lastName
                            nickName
                            phone
                            address
                            latitude
                            longitude
                            typeOfPlace
                            nameOfPlace
                            remark
                            country {
                                id
                                name
                            }
                            province {
                                id
                                name
                            }
                            district {
                                id
                                name
                            }
                            subDistrict {
                                id
                                name
                            }
                            postcode
                            createdAt
                            updatedAt
                        }
                    }
                }
            `,
            variables: {
                refId,
            },
        });
    },

    countryList: (paginate: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query getAllCountry($paginate: PaginateRequest!) {
                    data: listCountries(paginate: $paginate) {
                        data {
                            id
                            name
                            phoneCode
                            flag
                            primary
                        }
                    }
                }
            `,
            variables: {
                paginate,
            },
        }),

    countryPhoneCodeList: (paginate: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query countryPhoneCodeList($paginate: PaginateRequest!) {
                    data: listCountriesWithPhoneCode(paginate: $paginate) {
                        data {
                            id
                            name
                            phoneCode
                            flag
                            primary
                        }
                    }
                }
            `,
            variables: {
                paginate,
            },
        }),

    countryPrimary: () =>
        API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query getPrimaryCountry {
                    data: getPrimaryCountry {
                        data {
                            id
                            name
                            phoneCode
                            flag
                            primary
                        }
                    }
                }
            `,
        }),

    provinceList: (countryId: string, paginate: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query getProvinces(
                    $countryId: String!
                    $paginate: PaginateRequest!
                ) {
                    data: listProvinces(
                        countryId: $countryId
                        paginate: $paginate
                    ) {
                        data {
                            id
                            name
                        }
                    }
                }
            `,
            variables: {
                countryId,
                paginate,
            },
        }),

    districtList: (provinceId: string, paginate: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query getDistricts(
                    $provinceId: String!
                    $paginate: PaginateRequest!
                ) {
                    data: listDistricts(
                        provinceId: $provinceId
                        paginate: $paginate
                    ) {
                        data {
                            id
                            name
                        }
                    }
                }
            `,
            variables: {
                provinceId,
                paginate,
            },
        }),

    subDistrictList: (districtId: string, paginate: any) =>
        API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query getSubDistricts(
                    $districtId: String!
                    $paginate: PaginateRequest!
                ) {
                    data: listSubDistricts(
                        districtId: $districtId
                        paginate: $paginate
                    ) {
                        data {
                            id
                            name
                            postcode
                        }
                    }
                }
            `,
            variables: {
                districtId,
                paginate,
            },
        }),
};
