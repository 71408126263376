import { gql } from "@apollo/client";
import { F_PAGINATE } from "../app";

export const F_BRANCH = gql`
    fragment F_BRANCH on CustomerBranchDto {
        id
        name
        code
        # address {
        #     address
        #     country {
        #         ...F_COUNTRY
        #     }
        #     province {
        #         id
        #         name
        #     }
        #     district {
        #         id
        #         name
        #     }
        #     subDistrict {
        #         id
        #         name
        #     }
        #     postcode
        # }
    }
`;
export const F_BRANCH_BASIC = gql`
    fragment F_BRANCH_BASIC on AdminBranchBasic {
        id
        name
        code
    }
`;

export const F_BRANCH_DETAIL = gql`
    fragment F_BRANCH_DETAIL on CustomerBranchDto {
        id
        name
        code
        brandTaxId
        phone
        address {
            address
            country {
                id
                name
            }
            province {
                id
                name
            }
            district {
                id
                name
            }
            subDistrict {
                id
                name
            }
            postcode
        }
    }
`;

export const F_BRANCHES = gql`
    ${F_BRANCH}
    ${F_PAGINATE}
    fragment F_BRANCHES on CustomerBranchPaginateResponse {
        data {
            ...F_BRANCH
        }
        pagination {
            ...F_PAGINATE
        }
    }
`;
