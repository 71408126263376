import { useContext, useEffect, useMemo, useRef, useState } from "react";
import classNames from "classnames";
import { UrlUtils } from "d-react-components";
import { map, toUpper } from "lodash";
import Image from "../components/Image";
import { LANGUAGE_DEFAULT, SOCIAL_LINKS } from "../constant/app";
import {
    QuotationContext,
    appStateDefault,
    IAppState,
} from "../context/quotation";
import Routes from "./Routes";

interface ILanguageItem {
    lang: string;
}

const LanguageItem = ({ lang }: ILanguageItem) => {
    const { locale = LANGUAGE_DEFAULT } = UrlUtils.getQuery();
    const classNameLanguage = classNames("mx-1 text-white cursor-pointer");

    const onChangeLanguage = () => {
        UrlUtils.addQuery({ locale: lang });
    };

    return (
        <div
            className={classNames(classNameLanguage, {
                "app-header__language-selecting": locale === lang,
            })}
            onClick={onChangeLanguage}
        >
            {toUpper(lang)}
        </div>
    );
};

const FooterDesktop = () => (
    <div className="w-100 bg-primary py-3 display-desktop-layout">
        <div className="container flex-center justify-content-between">
            <small className="text-white">
                ©2023 by Dermaster Thailand. All rights reserved.
            </small>
            <div className="d-flex">
                {map(SOCIAL_LINKS, (social, index) => (
                    <a href={social.url} key={index}>
                        <Image
                            src={social.icon}
                            className="image-square-xx-small ml-2"
                        />
                    </a>
                ))}
            </div>
        </div>
    </div>
);
const FooterMobile = () => (
    <div className="w-100 bg-primary py-3 display-mobile-layout">
        <div className="flex-column flex-center justify-content-between">
            <small className="text-white">
                ©2023 by Dermaster Thailand. All rights reserved.
            </small>
            <div className="d-flex mt-3">
                {map(SOCIAL_LINKS, (social, index) => (
                    <a href={social.url} key={index}>
                        <Image
                            src={social.icon}
                            className="image-square-xx-small ml-2"
                        />
                    </a>
                ))}
            </div>
        </div>
    </div>
);

// const usePathInfo = () => {
//   const location = useLocation();
//   const pathName = location.pathname;
//   return useMemo(() => {
//     let menuSelecting;
//     MAIN_MENU.forEach((menuItem: any) => {
//       if (menuItem.path === pathName) {
//         menuSelecting = menuItem;
//       }

//       if (menuItem.subMenu?.length > 0) {
//         menuItem.subMenu.forEach((subMenuItem: any) => {
//           if (subMenuItem?.path && matchPath(subMenuItem?.path, pathName)) {
//             menuSelecting = subMenuItem;
//           }
//         });
//       }
//     });
//     return menuSelecting;
//   }, [pathName]);
// };

const AppLayoutContext = ({ children }: any) => {
    const [metaData, setMetaData] = useState<IAppState>(appStateDefault);
    //   const [siteConfig, setSiteConfig] = useState<ISiteConfig>({});
    //   const [getMyInfo] = useGetMyInfoLazyQuery();

    useEffect(() => {
        loadMetaData();
    }, []);

    const loadMetaData = () => {
        // // AppAPI.loadMetaData().then(setMetaData);
        // getMyInfo().then((resp) => {
        //   setMetaData({
        //     ...metaData,
        //     me: resp?.data?.getAdminProfileForAdmin?.data,
        //   });
        // });
    };

    return (
        <QuotationContext.Provider value={{ ...metaData }}>
            {children}
        </QuotationContext.Provider>
    );
};

function AppLayout() {
    return (
        <AppLayoutContext>
            <div className="w-100 h-100 flex-column">
                <div className="w-100 bg-primary">
                    <div className="container flex-center position-relative app-header py-3">
                        <Image
                            src="/images/logo-white.png"
                            style={{ height: "44px" }}
                        />

                        <div className="app-header__language">
                            <LanguageItem lang="en" />|
                            <LanguageItem lang="th" />
                        </div>
                    </div>
                </div>
                <div className="app-content">
                    <div className="container">
                        <Routes />
                    </div>
                    <FooterMobile />
                </div>
                <FooterDesktop />
            </div>
        </AppLayoutContext>
    );
}

export default AppLayout;
