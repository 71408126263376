import { gql } from "@apollo/client";
import API from "../API";

export default {
    paymentMethodList: () => {
        return API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query listAllPaymentConfigurationForCustomer {
                    data: listAllPaymentConfigurationForCustomer {
                        data {
                            id
                            title
                            description
                            paymentConfigurationType
                            priority
                            maximumOrderAmount
                            minimumOrderAmount
                        }
                    }
                }
            `,
        });
    },
    payment2C2PList: () => {
        return API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query listAllPayment2C2PServiceForCustomer {
                    data: listAllPayment2C2PServiceForCustomer {
                        data {
                            id
                            title
                            option
                            description
                            priority
                            maximumOrderAmount
                            minimumOrderAmount
                        }
                    }
                }
            `,
        });
    },
    bankList: (paymentMethod: string, branch: string) => {
        return API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query listAllBankForCustomer(
                    $paymentMethod: PaymentMethod!
                    $branch: String!
                ) {
                    data: listAllBankForCustomer(
                        paymentMethod: $paymentMethod
                        branch: $branch
                    ) {
                        data {
                            id
                            bankName
                            bankLogo
                            accountName
                            accountNumber
                            qrPayment
                        }
                    }
                }
            `,
            variables: { paymentMethod, branch },
        });
    },
    getUrlPayment: (id: string, payload: any) => {
        return API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).query({
            query: gql`
                query urlPaymentForCustomer(
                    $id: String!
                    $payload: UrlCustomerPaymentRequest!
                ) {
                    data: urlPaymentForCustomer(id: $id, payload: $payload) {
                        data {
                            url
                            paymentMethod
                            paymentOption
                        }
                    }
                }
            `,
            variables: { id, payload },
        });
    },
    uploadProof: (
        refId: string,
        id: string,
        paymentMethod: string,
        sourceOfPayment: string,
        payload: any
    ) => {
        return API.withConverter({
            fromServer: (res) => res?.data?.data?.data ?? [],
        }).mutate({
            mutation: gql`
                mutation uploadProofForCustomer(
                    $refId: String!
                    $id: String!
                    $paymentMethod: PaymentMethod!
                    $sourceOfPayment: SourceOfPayment!
                    $payload: UploadProofCustomerPaymentRequest!
                ) {
                    data: uploadProofForCustomer(
                        refId: $refId
                        id: $id
                        paymentMethod: $paymentMethod
                        sourceOfPayment: $sourceOfPayment
                        payload: $payload
                    ) {
                        data {
                            id
                            paymentNo
                            status
                        }
                    }
                }
            `,
            variables: { refId, id, paymentMethod, sourceOfPayment, payload },
        });
    },
};
