export const ORDER_STATUS = {
    PENDING_PAYMENT: "PENDING_PAYMENT",
    PENDING_CONFIRMATION: "PENDING_CONFIRMATION",
    PARTIALLY_PAID: "PARTIALLY_PAID",
    ORDER_PROCESSING: "ORDER_PROCESSING",
    DELIVERY_PROCESSING: "DELIVERY_PROCESSING",
    COMPLETED: "COMPLETED",
    CANCELLED: "CANCELLED",
    EXPIRED: "EXPIRED",
    DELIVERED: "DELIVERED",
    REFUNDED: "REFUNDED",
};

export const ORDER_STATUSES = [
    { id: "PENDING_PAYMENT", label: "pending", color: "#FAC256" },
    {
        id: "PENDING_CONFIRMATION",
        label: "pendingConfirmation",
        color: "#FAC256",
    },
    { id: "PARTIALLY_PAID", label: "partiallyPaid", color: "#FAC256" },
    { id: "ORDER_PROCESSING", label: "processing", color: "#84C4FF" },
    {
        id: "DELIVERY_PROCESSING",
        label: "deliveryProcessing",
        color: "#84C4FF",
    },
    { id: "COMPLETED", label: "completed", color: "#33B950" },
    { id: "CANCELLED", label: "cancelled", color: "#C4C4C4" },
    { id: "EXPIRED", label: "expired", color: "#FF1100" },
    { id: "DELIVERED", label: "delivered", color: "#33B950" },
    { id: "REFUNDED", label: "refunded", color: "#33B950" },
];

export const ORDER_FEES = [
    { id: "transportation", label: "transportationFee" },
];
