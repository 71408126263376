export default {
    ROOT: "/",

    /* ----------------------------------------
    // QUOTATION
    // ---------------------------------------- */
    QUOTATION: "/q/:id",

    /* ----------------------------------------
    // SO
    // ---------------------------------------- */
    ORDER: "/o/:id",

    /* ----------------------------------------
    // APPOINTMENT
    // ---------------------------------------- */
    APPOINTMENT: "/a",

    /* ----------------------------------------
    // REGISTER
    // ---------------------------------------- */
    REGISTER: "/sr",

    /* ----------------------------------------
    // UPDATE INFO
    // ---------------------------------------- */
    UPDATE_INFO: "/ui/:customerId",

    /* ----------------------------------------
    // HEALTH ASSESSMENT
    // ---------------------------------------- */
    HEALTH_ASSESSMENT: "/ha/:customerId",
};
