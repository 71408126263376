import { UrlUtils, Progress } from "d-react-components";
import { isEmpty, sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AddressAPI from "../../api/address/AddressAPI";
import OrderAPI from "../../api/order/OrderAPI";
import PaymentAPI from "../../api/payment/PaymentAPI";
import QuotationAPI from "../../api/quotation/QuotationAPI";
import { PaymentMethod } from "../../constant/payment";
import { OrderContext } from "../../context/order";
import { IOrder } from "../../interface/order";
import { IPaymentMethod } from "../../interface/paymentMethod";
import OrderContact from "./OrderContact";
import OrderGeneralInfo from "./OrderGeneralInfo";
import OrderPayment from "./OrderPayment";
import OrderProductsDesktop from "./OrderProductsDesktop";
import OrderProductsMobile from "./OrderProductsMobile";
import OrderShippingAddress from "./OrderShippingAddress";
import { IBranch } from "../../interface/branch";
import BranchAPI from "../../api/branch/BranchAPI";

const Order = () => {
    const { id } = useParams();
    const [branch, setBranch] = useState<IBranch>();
    const [orderDetail, setOrderDetail] = useState<IOrder>();
    const [shippings, setShippings] = useState<any[]>([]);
    const [splitPayment, setSplitPayment] = useState<any[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [countryList, setCountryList] = useState<any[]>([]);

    useEffect(() => {
        loadOrderDetail();
        loadShippingList();
        loadCountryList();
        loadPaymentList();
        loadPaymentMethodList();
    }, []);

    const loadOrderDetail = () => {
        if (isEmpty(id)) return;

        Progress.show(
            { method: OrderAPI.detail, params: [id] },
            setOrderDetail as any
        );
    };

    const loadShippingList = () => {
        AddressAPI.addressList(id as string).then((resp) => {
            setShippings(resp?.data?.listAllShippingForCustomer?.data);
        });
    };

    const loadCountryList = () => {
        AddressAPI.countryList({
            page: 1,
            limit: 10000,
        }).then((resp) => {
            setCountryList(sortBy(resp, "name"));
        });
    };

    const loadPaymentList = () => {
        QuotationAPI.loadPayments(id as string).then((resp) => {
            setSplitPayment(resp);
        });
    };

    const loadPaymentMethodList = async () => {
        const listPaymentMethod = await PaymentAPI.paymentMethodList();
        const listPayment2C2P = await PaymentAPI.payment2C2PList();

        setPaymentMethods([
            ...listPaymentMethod
                .filter(
                    (payment: any) =>
                        payment.paymentConfigurationType !==
                        PaymentMethod.PAYMENT_2C2P
                )
                .map((payment: any) => ({
                    ...payment,
                    key: `${payment.paymentConfigurationType}`,
                })),
            ...listPayment2C2P.map((payment: any) => ({
                ...payment,
                paymentConfigurationType: PaymentMethod.PAYMENT_2C2P,
                key: `${PaymentMethod.PAYMENT_2C2P}_${payment.option}`,
            })),
        ]);
    };

    useEffect(() => {
        if (orderDetail?.store?.id) {
            BranchAPI.detail(orderDetail?.store?.id).then((resp) => {
                setBranch(resp?.data?.data?.data);
            });
        }
    }, [orderDetail?.store?.id]);

    return (
        <OrderContext.Provider
            value={{
                orderDetail: orderDetail as IOrder,
                loadOrderDetail,
                setOrderDetail,
                shippings,
                loadShippingList,
                countryList,
                splitPayment,
                paymentMethods,
                loadPaymentList,
                branch,
            }}
        >
            {orderDetail && (
                <>
                    <OrderGeneralInfo />
                    <OrderProductsDesktop />
                    <OrderProductsMobile />
                    <OrderContact />
                    <OrderShippingAddress />
                    <OrderPayment />
                </>
            )}
        </OrderContext.Provider>
    );
};

export default Order;
