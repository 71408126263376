import { gql } from "@apollo/client";
import API from "../API";
import { F_APPOINTMENT } from "./AppointmentFragment";

export const getAppointment = gql`
    ${F_APPOINTMENT}
    query getAppointment($id: String!) {
        data: detailAppointmentForCustomer(id: $id) {
            data {
                ...F_APPOINTMENT
            }
        }
    }
`;
export const createAppointment = gql`
    ${F_APPOINTMENT}
    mutation createAppointment($payload: CustomerAppointmentCreateRequest!) {
        data: createAppointmentForCustomer(payload: $payload) {
            data {
                ...F_APPOINTMENT
            }
        }
    }
`;

const appointmentDetailConverter = {
    fromServer: (res: any) => {
        const detail = res?.data?.data?.data ?? [];
        return detail;
    },
};

const AppointmentAPI = {
    detail: async (id: any) =>
        API.withConverter(appointmentDetailConverter).query({
            query: getAppointment,
            variables: { id },
            fetchPolicy: "no-cache",
        }),

    create: async (payload: any) =>
        API.withConverter(appointmentDetailConverter).mutate({
            mutation: createAppointment,
            variables: { payload },
            fetchPolicy: "no-cache",
        }),
};

export default AppointmentAPI;
