import {
    Button,
    Checkbox,
    CheckboxGroup,
    InputText,
    Modal,
    ModalProps,
    Notifications,
    Progress,
} from "d-react-components";
import { useFormik } from "formik";
import { find, forEach, includes, map } from "lodash";
import React, { useContext, useEffect, useState } from "react";
import { useMedia } from "react-use";
import * as Yup from "yup";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import SignatureField from "../../../components/signature/SignatureField";
import { APP_WIDE_SCREEN_WIDTH } from "../../../constant/app";
import { ISurvey, SurveyQuestionType } from "../../../interface/customer";
import Messages from "../../../languages/Messages";
import SubmitSuccess from "../../registration/form/shared/SubmitSuccess";
import { HealthAssessmentContext } from "../HealthAssessment";

export interface IHealthAssessmentFormProps {
    [key: string]: any;
}

interface ISurveyQuestion {
    survey: ISurvey;
    answer: string[];
    disabled?: boolean;
    onChange?: any;
}

export interface IHealthAssessmentModalConfirmProps
    extends Omit<ModalProps, "children"> {
    onConfirm?: any;
}

const HealthAssessmentForm: React.FC<IHealthAssessmentFormProps> = ({ id }) => {
    const {
        authState,
        setAuthState,
        customerId = "",
        formValueState,
    } = useContext(HealthAssessmentContext);
    const { accessToken = "" } = authState;

    const inputClassName = "col-span-12 md:col-span-6 mt-3";
    const isWide = useMedia(`(min-width: ${APP_WIDE_SCREEN_WIDTH}px)`);
    const [openConfirmModal, setOpenConfirmModal] = useState(false);
    const [surveyQuestionList, setSurveyQuestionList] = useState<Array<any>>();
    const [showSuccess, setShowSuccess] = useState<boolean>(false);

    const healthForm = useFormik<any>({
        initialValues: { confirm: false },
        validateOnChange: false,
        // validationSchema: activeTab.schema,
        enableReinitialize: true,
        onSubmit: () => {
            setOpenConfirmModal(true);
        },
    });

    const { values, errors, handleSubmit, setFieldValue } = healthForm ?? {};

    // console.log(
    //     "🚀 >>>>>> file: HealthAssessmentForm.tsx:63 >>>>>> values:",
    //     values
    // );

    useEffect(() => {
        if (customerId) {
            loadHealthAssessmentData();
        }
    }, [customerId]);

    const loadHealthAssessmentData = async () => {
        try {
            const res = await CustomerAPI.loadHealthAssessmentData({
                customerId,
                accessToken,
            });
            const { formData = [], surveyAnswers = [] } = res?.data?.data ?? {};
            const mappedAnw = [...surveyAnswers];
            const questIds = map(surveyAnswers, (item) => item?.questionId);
            forEach(formData, (item) => {
                if (!includes(questIds, item?.id)) {
                    mappedAnw.push({ questionId: item?.id });
                }
            });
            setSurveyQuestionList(formData);
            setFieldValue("answers", mappedAnw);
        } catch (error: any) {
            Notifications.showError(error?.message ?? "");
            setAuthState({ isAllowed: false, accessToken: "" });
        }
    };

    const onUpdateValues = (survey: any, newValue: any[]) => {
        console.log(
            "🚀 >>>>>> file: HealthAssessmentForm.tsx:97 >>>>>> onUpdateValues >>>>>> newValue:",
            newValue
        );
        console.log(
            "🚀 >>>>>> file: HealthAssessmentForm.tsx:97 >>>>>> onUpdateValues >>>>>> survey:",
            survey
        );
        const result = map(values?.answers, (item) => {
            if (item.questionId === survey.id) {
                return { questionId: survey.id, answers: newValue };
            }
            return item;
        });
        setFieldValue("answers", result);
    };

    const onSubmitHealthAssessment = (confirm: any) => {
        const payload = {
            input: { ...confirm, surveyAnswers: values?.answers },
            accessToken,
            customerId,
        };
        return Progress.show(
            { method: CustomerAPI.createHealthAssessment, params: [payload] },
            (res) => {
                setShowSuccess(true);
            },
            (error: any) => {
                Notifications.showError(error?.message ?? "");
                setAuthState({ isAllowed: false, accessToken: "" });
            }
        );
    };

    if (showSuccess) {
        return <SubmitSuccess />;
    }

    return (
        <div>
            {/* <InputTextForm
                multiple
                required
                form={healthForm}
                keyData="allergiesMedication"
                className={inputClassName}
                label={Messages.listAnyAllergies}
            />
            <InputTextForm
                multiple
                required
                form={healthForm}
                keyData="takingMedication"
                className={inputClassName}
                label={Messages.listTakingMedications}
            />
            <InputTextForm
                multiple
                required
                form={healthForm}
                keyData="takingMedication"
                className={inputClassName}
                label={Messages.listTakingMedications}
            />
            <CheckboxGroup
                label={Messages.listTakingMedications}
                className={inputClassName}
                dataSource={ATTRIBUTE_INPUT_TYPE}
                numberOfColumns={isWide ? undefined : "2"}
            />
            <div className="flex items-start mt-3">
                <Checkbox />
                <div className="ml-2">
                    {Messages.healthAssessmentConfirmation}
                </div>
            </div> */}
            {map(surveyQuestionList, (survey) => {
                const answer = find(
                    values?.answers,
                    (item) => item.questionId === survey.id
                );

                console.log(
                    "🚀 >>>>>> file: HealthAssessmentForm.tsx:157 >>>>>> {map >>>>>> answer:",
                    answer
                );
                return (
                    <SurveyQuestion
                        key={`${survey?.id}`}
                        survey={survey}
                        answer={answer?.answers ?? []}
                        onChange={(values: any) => {
                            onUpdateValues(survey, values);
                        }}
                    />
                );
            })}
            <Checkbox
                className="mt-3"
                label={Messages.confirmHealthSubmit}
                onChange={(v) => setFieldValue("confirm", !values?.confirm)}
                checked={values?.confirm}
            />
            <Button
                className="w-full my-5"
                style={{ width: "100%" }}
                onClick={handleSubmit as any}
            >
                {Messages.submit}
            </Button>
            {openConfirmModal && (
                <HealthAssessmentModalConfirm
                    open={openConfirmModal}
                    onClose={() => setOpenConfirmModal(false)}
                    onConfirm={(confirmData: any) => {
                        onSubmitHealthAssessment(confirmData);
                    }}
                />
            )}
        </div>
    );
};

export default HealthAssessmentForm;

// @ts-ignore
export const HealthAssessmentConfirmSchema = Yup.object().shape<any>({
    customerConfirm: Yup.object().required("Required"),
    witnessConfirm: Yup.object().required("Required"),
});

const HealthAssessmentModalConfirm: React.FC<
    IHealthAssessmentModalConfirmProps
> = ({ open, onClose, onConfirm, ...rest }) => {
    const confirmForm = useFormik<any>({
        initialValues: {},
        validateOnChange: false,
        validationSchema: HealthAssessmentConfirmSchema,
        enableReinitialize: true,
        onSubmit: (values: any) => {
            onConfirm && onConfirm(values);
        },
    });
    const { values, errors, handleSubmit, setFieldValue } = confirmForm ?? {};

    return (
        <Modal
            open={open}
            onClose={onClose}
            {...rest}
            title={Messages.confirm}
            saveText={Messages.confirm}
            onSave={handleSubmit as any}
        >
            <label className="mt-3">{Messages.clientSignature}</label>
            <SignatureField
                onChange={(v: any) =>
                    setFieldValue("customerConfirm", {
                        updatedAt: new Date(),
                        signature: v,
                    })
                }
                value={values?.customerConfirm?.signature}
                error={errors?.customerConfirm}
            />
            <label className="mt-3">{Messages.witnessSignature}</label>
            <SignatureField
                onChange={(v: any) =>
                    setFieldValue("witnessConfirm", {
                        updatedAt: new Date(),
                        signature: v,
                    })
                }
                value={values?.witnessConfirm?.signature}
                error={errors?.witnessConfirm}
            />
        </Modal>
    );
};

export const SurveyQuestion = ({
    survey,
    answer,
    disabled = false,
    onChange,
}: ISurveyQuestion) => {
    const content = () => {
        switch (survey.type) {
            case SurveyQuestionType.CHECK_BOX:
                return (
                    <CheckboxGroup
                        numberOfColumns="3"
                        dataSource={survey?.answers ?? []}
                        label={
                            <div className="text-primary">{survey?.label}</div>
                        }
                        value={answer}
                        disabled={disabled}
                        onChange={onChange}
                    />
                );

            default:
                return (
                    <InputText
                        label={
                            <div className="text-primary mb-3">
                                {survey?.label}
                            </div>
                        }
                        value={answer}
                        disabled={disabled}
                        onChange={(e) => onChange && onChange(e?.target?.value)}
                        multiple
                    />
                );
        }
    };

    return <div className="mt-3 border p-3">{content()}</div>;
};
