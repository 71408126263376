import { useFirstTime } from "d-react-components";
import React, { Dispatch, useEffect, useState } from "react";
import { useParams } from "react-router";
import { loadFromLocal, saveToLocal } from "../../data/local-storage";
import { IRegistrationAuthState } from "../registration/Registration";
import HealthAssessmentForm from "./content/HealthAssessmentForm";
import HealthAssessmentVerify from "./content/HealthAssessmentVerify";

const HEALTH_ASSESSMENT_DATA_KEY = "HEALTH_ASSESSMENT_DATA_KEY";

interface IHealthAssessmentContextState {
    customerId?: string;
    authState: IRegistrationAuthState;
    setAuthState: Dispatch<IRegistrationAuthState>;
    formValueState?: any;
    setFormValueState: Dispatch<any>;
}

const healthAssessmentContextState: IHealthAssessmentContextState = {
    authState: {},
    setAuthState: () => {},
    formValueState: {},
    setFormValueState: () => {},
};

export const HealthAssessmentContext = React.createContext(
    healthAssessmentContextState
);

export interface IHealthAssessmentProps {
    [key: string]: any;
}

const HealthAssessment: React.FC<IHealthAssessmentProps> = ({ id }) => {
    const { customerId } = useParams();
    const isFirstTime = useFirstTime();
    const [authState, setAuthState] = useState<IRegistrationAuthState>({
        isAllowed: false,
        ...(loadFromLocal(HEALTH_ASSESSMENT_DATA_KEY)?.authState ?? {}),
    });
    const [formValueState, setFormValueState] = useState<any>();

    useEffect(() => {
        if (isFirstTime) {
            return;
        }
        const savedData = loadFromLocal(HEALTH_ASSESSMENT_DATA_KEY);
        saveToLocal(HEALTH_ASSESSMENT_DATA_KEY, {
            ...(savedData || {}),
            authState,
        });
    }, [authState?.isAllowed, authState?.accessToken]);

    return (
        <HealthAssessmentContext.Provider
            value={{
                customerId,
                authState,
                formValueState,
                setFormValueState,
                setAuthState,
            }}
        >
            {!authState?.isAllowed ? (
                <HealthAssessmentVerify />
            ) : (
                <HealthAssessmentForm jobApp={{}} />
            )}
        </HealthAssessmentContext.Provider>
    );
};

export default HealthAssessment;
