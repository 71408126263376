import classNames from "classnames";
import {
    Button,
    DateInput,
    Notifications,
    Progress,
    TabBar,
    TimeUtils,
    UrlUtils,
    useFirstTime,
    ViewTextError,
} from "d-react-components";
import { useFormik } from "formik";
import { filter, find, findIndex, map, reduce } from "lodash";
import moment from "moment";
import { useContext, useEffect, useRef, useState } from "react";
import CurrencyFormat from "react-currency-format";
import Carousel, { ModalGateway, Modal as ModalImage } from "react-images";
import { Link } from "react-router-dom";
import PaymentAPI from "../../api/payment/PaymentAPI";
import Image from "../../components/Image";
import InputFileArea from "../../components/InputFileArea";
import InputSelectForm from "../../components/InputSelectForm";
import InputTextForm from "../../components/InputTextForm";
import SectionHeader from "../../components/SectionHeader";
import { UploadType } from "../../constant/app";
import {
    PAYMENT_STATUS,
    PaymentMethod,
    SourceOfPayment,
} from "../../constant/payment";
import {
    PaymentDetailContext,
    QuotationContext,
} from "../../context/quotation";
import { mapOrderPaymentProofToServer } from "../../interface/order";
import { IBankAccount, IPayment } from "../../interface/payment";
import Messages from "../../languages/Messages";
import { PaymentBankProofSchema } from "../../schema/order";
import {
    BankTransferProof,
    MethodTabView,
    PaymentsProceededContent,
    PaymentStatus,
} from "../order/OrderPayment";
import Path from "../Path";

const BankTransferProceed = ({ method }: any) => {
    const { quotationDetail, loadPaymentList } = useContext(QuotationContext);
    const [bankAccounts, setBankAccounts] = useState<IBankAccount[]>([]);
    const { payment } = useContext(PaymentDetailContext);
    const uploadRef = useRef<any>();

    const [imageToView, setImageToView] = useState<any>({
        isVisible: false,
        index: 0,
    });

    useEffect(() => {
        PaymentAPI.bankList(method, quotationDetail?.store?.id as string).then(
            (resp) => setBankAccounts(resp as IBankAccount[])
        );
    }, []);

    const proofForm = useFormik<any>({
        initialValues: { method } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: PaymentBankProofSchema,
        onSubmit: (values: any) => {
            const input = mapOrderPaymentProofToServer(values);
            onUploadProofPayment(input);
        },
    });
    const formValues = proofForm?.values;
    const formErrors = proofForm?.errors;

    const onUploadProofPayment = (input: any) => {
        Progress.show(
            {
                method: PaymentAPI.uploadProof as any,
                params: [
                    quotationDetail.id,
                    payment?.id,
                    method,
                    SourceOfPayment.QUOTATION,
                    input,
                ],
            },
            (orderDetailRes: any) => {
                Notifications.showSuccess(Messages.uploadProofPaymentSuccess);
                loadPaymentList();
                proofForm.setValues({ method });
                uploadRef.current.refresh();
            }
        );
    };

    const mapProofImage = (url: string) => ({
        source: url,
        caption: "",
    });

    const allPaymentSlip = reduce(
        payment.proof,
        (slips: any, item: any) => {
            const result = map(item.attachments, mapProofImage);
            return [...slips, ...result];
        },
        []
    );

    const classNameInput = "mt-3";

    const onClickSlip = (slip: string) =>
        setImageToView({
            isVisible: true,
            index: findIndex(
                allPaymentSlip,
                (item: any) => item.source === slip
            ),
        });

    const renderBankSelect = (item: IBankAccount) => {
        return (
            <div className="d-flex align-items-center">
                <Image
                    src={item?.bankLogo}
                    className="image-square-x-small object-contain"
                />
                <div className="ml-1">
                    {`${item.bankName} // ${item?.accountNumber}`}
                </div>
            </div>
        );
    };

    return (
        <div>
            <text>{`${Messages.paymentShouldBeMade} ${TimeUtils.toDateTime(
                quotationDetail?.expired
            )}. ${Messages.onceYouMadeThePayment}`}</text>
            <div className="border p-4 mt-3">
                <div className="row">
                    {map(bankAccounts, (bank, index) => (
                        <div
                            className="col-12 d-flex mt-3 col-sm-6"
                            key={index}
                        >
                            <Image
                                src={bank?.bankLogo}
                                className="image-square-small object-cover"
                            />
                            <div className="flex-column ml-3">
                                <small>{bank?.bankName}</small>
                                <small>{bank.accountName}</small>
                                <small>{bank?.accountNumber}</small>
                            </div>
                        </div>
                    ))}
                </div>
            </div>
            <label className="mt-3">{Messages.receiptOfPayment}</label>
            <div className="mt-3 flex-column">
                {map(payment?.proof ?? [], (proof) => (
                    <BankTransferProof
                        proof={proof}
                        onClickSlip={onClickSlip}
                    />
                ))}
                {/* @ts-ignore */}
                <ModalGateway>
                    {imageToView.isVisible ? (
                        //@ts-ignore
                        <ModalImage
                            onClose={() => setImageToView({ isVisible: false })}
                        >
                            {/* @ts-ignore */}
                            <Carousel
                                views={allPaymentSlip}
                                currentIndex={imageToView.index}
                            />
                        </ModalImage>
                    ) : null}
                </ModalGateway>
            </div>
            {allPaymentSlip?.length < 2 && (
                <>
                    <InputFileArea
                        onChange={(value: any) =>
                            proofForm.setFieldValue("attachment", value)
                        }
                        className="mt-3"
                        ref={uploadRef}
                        inputParam={{ uploadType: UploadType.QUOTATION }}
                    />
                    <ViewTextError error={formErrors.attachment} />
                    <InputTextForm
                        form={proofForm}
                        keyData="nameOfPayer"
                        className={classNameInput}
                        label={Messages.fullNameTransferee}
                        required
                    />
                    <InputSelectForm
                        form={proofForm}
                        keyData="bankAccountId"
                        getLabel={renderBankSelect}
                        dataSource={bankAccounts}
                        className={classNameInput}
                        label={Messages.bankTransferTo}
                        required
                    />
                    <DateInput
                        label={Messages.dateOfTransfer}
                        onChange={(val) =>
                            proofForm.setFieldValue(
                                "dateOfPayment",
                                moment(val).toISOString()
                            )
                        }
                        value={
                            formValues.dateOfPayment
                                ? moment(formValues.dateOfPayment)
                                : (null as any)
                        }
                        className={classNameInput}
                        error={formErrors.dateOfPayment as any}
                        required
                    />
                    <div className="d-flex justify-content-end mt-3">
                        <Button onClick={() => proofForm.handleSubmit()}>
                            {Messages.proceed}
                        </Button>
                    </div>
                </>
            )}
        </div>
    );
};

const C2PProceed = ({ method }: any) => {
    const { quotationDetail, paymentMethods } = useContext(QuotationContext);
    const { payment } = useContext(PaymentDetailContext);
    const [urlPayment, setUrlPayment] = useState<any>();

    const onProceed = () => {
        window.open(urlPayment?.url, "_self");
    };

    useEffect(() => {
        setUrlPayment("");
        const selectedMethod = paymentMethods?.find((m) => m.key === method);
        PaymentAPI.getUrlPayment(payment.id, {
            paymentMethod: selectedMethod?.paymentConfigurationType,
            paymentOption: selectedMethod?.option,
        }).then((resp) => {
            setUrlPayment(resp);
        });
    }, [method]);

    return (
        <div className="p-4">
            <div>{Messages.paySecurelyByUsing2C2P}</div>
            <Image
                src="/images/payment/2c2p.png"
                className="image-reg-x-large w-100"
            />
            {urlPayment && (
                <div className="d-flex justify-content-end">
                    <Button onClick={onProceed} className="mt-3">
                        {Messages.proceed}
                    </Button>
                </div>
            )}
        </div>
    );
};

const PaymentDetail = () => {
    const { paymentMethods } = useContext(QuotationContext);
    const { payment } = useContext(PaymentDetailContext);
    const defaultMethodObject = find(paymentMethods, (method) => {
        if (method.paymentConfigurationType === PaymentMethod.PAYMENT_2C2P) {
            return (
                method.option === payment.paymentOption &&
                payment.paymentMethod === PaymentMethod.PAYMENT_2C2P
            );
        }
        return payment.paymentMethod === method.paymentConfigurationType;
    });

    console.log("paymentMethods", paymentMethods);
    const methodDefault = defaultMethodObject
        ? defaultMethodObject?.key
        : paymentMethods?.[0]?.key;
    const [methodSelected, setMethodSelected] = useState<string>(
        methodDefault as string
    );
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        setMethodSelected(methodDefault as string);
    }, [payment?.id]);

    const methodTabs = filter(
        map(paymentMethods, (item) => ({
            ...item,
            disabled:
                item.paymentConfigurationType === PaymentMethod.CASH ||
                (item.minimumOrderAmount &&
                    payment.total < item.minimumOrderAmount) ||
                (item.maximumOrderAmount &&
                    payment.total > item.maximumOrderAmount),
        })),
        (item) =>
            item.paymentConfigurationType !== PaymentMethod.DEFAULT &&
            item.paymentConfigurationType !== PaymentMethod.CASH &&
            item.paymentConfigurationType !==
                PaymentMethod.EMPLOYEE_SALARY_DEDUCTION &&
            item.paymentConfigurationType !== PaymentMethod.PAID_BY_CHECK &&
            item.paymentConfigurationType !== PaymentMethod.CREDIT_CARD_POS
    );

    const renderInfo = (title: string, content: any) => (
        <div className="d-flex mt-1">
            <text className="font-weight-bold">{`${title}: `}</text>
            <text>{content}</text>
        </div>
    );

    const renderPaymentProceed = () => {
        const method = find(
            paymentMethods,
            (method) => method.key === methodSelected
        );
        switch (method?.paymentConfigurationType) {
            case PaymentMethod.BANK_TRANSFER:
                return <BankTransferProceed method={methodSelected} />;
            case PaymentMethod.PAYMENT_2C2P:
                return <C2PProceed method={methodSelected} />;
            default:
                return <div />;
        }
    };
    if (payment?.status === PAYMENT_STATUS.CONFIRMED) {
        return (
            <PaymentsProceededContent
                content={Messages.yourOrderPartiallyPaid}
            />
        );
    }

    return (
        <div className="flex-column mt-3">
            {renderInfo(
                Messages.amount,
                // StringUtils.moneyThaiFormat(payment.total)
                <CurrencyFormat
                    displayType="text"
                    thousandSeparator
                    value={payment.total}
                    decimalScale={2}
                    fixedDecimalScale
                />
            )}
            {renderInfo(Messages.paymentRef, payment?.paymentNo)}
            <div className="font-weight-bold mt-1">
                {Messages.paymentMethod}
                {payment?.paymentMethod !== PaymentMethod.DEFAULT && (
                    <>: {defaultMethodObject?.title}</>
                )}
            </div>
            <div className="row mt-3">
                {payment?.paymentMethod === PaymentMethod.DEFAULT && (
                    <div className="col-12 col-sm-4">
                        <MethodTabView
                            dataSource={methodTabs}
                            value={methodSelected}
                            onChange={(value: any) =>
                                setMethodSelected(value.key)
                            }
                            disabled={
                                payment?.paymentMethod !== PaymentMethod.DEFAULT
                            }
                            renderTabContent={() => (
                                <div className="display-mobile-layout p-3 border-left border-right">
                                    {renderPaymentProceed()}
                                    <PaymentStatus payment={payment} />
                                </div>
                            )}
                        />
                    </div>
                )}
                <div
                    className={classNames(
                        {
                            "col-sm-8 display-desktop-layout":
                                payment?.paymentMethod ===
                                PaymentMethod.DEFAULT,
                        },
                        `col-12`
                    )}
                >
                    {renderPaymentProceed()}
                    <PaymentStatus payment={payment} />
                </div>
            </div>
        </div>
    );
};

const PaymentsProceeding = () => {
    const { paymentId } = UrlUtils.getQuery();

    const { splitPayment, paymentMethods } = useContext(QuotationContext);
    const defaultPayment =
        find(splitPayment, (item) => item.id === paymentId) ??
        splitPayment?.[0];

    const [paymentSelected, setPaymentSelected] =
        useState<IPayment>(defaultPayment);
    const isFirstTime = useFirstTime();

    useEffect(() => {
        if (isFirstTime) return;
        setPaymentSelected(splitPayment?.[0]);
    }, [splitPayment]);

    return (
        <div className="card-container mt-3 p-4">
            <SectionHeader label={Messages.payment} hiddenEdit />
            <TabBar
                className="mt-3"
                dataSource={map(splitPayment, (item, index) => ({
                    ...item,
                    label: `${Messages.payment} ${index + 1}`,
                }))}
                onChange={setPaymentSelected as any}
                value={paymentSelected}
            />
            <PaymentDetailContext.Provider value={{ payment: paymentSelected }}>
                {paymentMethods?.length && <PaymentDetail />}
            </PaymentDetailContext.Provider>
        </div>
    );
};

const PaymentsProceeded = () => {
    const { quotationDetail } = useContext(QuotationContext);
    let icon;

    const description = (
        <div className="flex-column justify-content-center align-items-center mt-3">
            <span className="mb-3">{Messages.orderPaid}</span>
            <Link
                to={{
                    pathname: Path.APPOINTMENT,
                    search: `?quotationRef=${quotationDetail.quotationNo}&quotationId=${quotationDetail.id}`,
                }}
            >
                <Button className="text-white" color="primary">
                    {Messages.bookAppointment}
                </Button>
            </Link>
        </div>
    );

    return (
        <div className="card-container mt-3 p-4">
            <SectionHeader label={Messages.payment} hiddenEdit />
            <PaymentsProceededContent icon={icon} content={description} />
        </div>
    );
};

const OrderPayment = () => {
    const { quotationDetail, splitPayment } = useContext(QuotationContext);

    const isPendingPayment = find(
        splitPayment,
        (payment) =>
            payment.status === PAYMENT_STATUS.PENDING ||
            payment.status === PAYMENT_STATUS.PENDING_CONFIRM
    );

    if (!isPendingPayment) {
        return <PaymentsProceeded />;
    }
    return <PaymentsProceeding />;
};

export default OrderPayment;
