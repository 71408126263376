import { Icon } from "d-react-components";
import ClassNames from "classnames";
import React from "react";
import REGISTRATION_FORM_DATA from "./RegistrationFormData";
import Messages from "../../../languages/Messages";

export interface IRegistrationFormTabProps {
    className?: string;
    activeIndex: number;
}

const RegistrationFormTab: React.FC<IRegistrationFormTabProps> = ({
    className,
    activeIndex,
}) => {
    return (
        <div className={`col-span-1 ${className}`}>
            <div className="d-none d-md-block card-container px-4 py-3">
                {REGISTRATION_FORM_DATA.map((formItem, index) => {
                    return (
                        <div
                            className={`flex-center-y my-3 hover-pointer ${ClassNames(
                                {
                                    "text-primary font-weight-bold":
                                        index <= activeIndex,
                                    "text-muted": index > activeIndex,
                                }
                            )}`}
                            key={index}
                            style={{}}
                        >
                            <Icon name="check_circle" />
                            <div className="ml-2">
                                {
                                    Messages[
                                        formItem.label as keyof typeof Messages
                                    ]
                                }
                            </div>
                        </div>
                    );
                })}
            </div>
            <div className="d-flex d-md-none w-full max-w-screen overflow-scroll justify-center items-start my-3 py-3">
                {REGISTRATION_FORM_DATA.map((formItem, index) => {
                    const icon = (
                        <div
                            className={`flex flex-col items-center justify-center ${ClassNames(
                                {
                                    "text-primary font-weight-bold":
                                        index <= activeIndex,
                                    "text-muted": index > activeIndex,
                                }
                            )}`}
                        >
                            <Icon name="check_circle" size="large" />
                            <div
                                className="mt-2 text-xs break-words text-center"
                                style={{ maxWidth: "50px" }}
                            >
                                {
                                    Messages[
                                        formItem.mobileLabel as keyof typeof Messages
                                    ]
                                }
                            </div>
                        </div>
                    );

                    const divider = (
                        <div
                            className={`w-6 mt-2 ${ClassNames("", {
                                "bg-primary": index <= activeIndex - 1,
                                "bg-gray-600": index > activeIndex - 1,
                                "d-none":
                                    index === REGISTRATION_FORM_DATA.length - 1,
                            })}`}
                            style={{ height: "1.5px" }}
                        />
                    );

                    return (
                        <div className="flex items-start">
                            {icon} {divider}
                        </div>
                    );
                })}
            </div>
        </div>
    );
};

export default RegistrationFormTab;
