/* eslint-disable no-continue */
import classNames from "classnames";
import {
    Icon,
    Notifications,
    ObjectUtils,
    StringUtils,
    useFirstTime,
} from "d-react-components";
import { find, includes, map, some, split } from "lodash";
import React, {
    forwardRef,
    useEffect,
    useImperativeHandle,
    useState,
} from "react";
import { useDropzone } from "react-dropzone";
import MediaAPI from "../api/order/MediaAPI";
import Messages from "../languages/Messages";

interface IInputFileArea {
    onChange: any;
    inputParam?: any;
    className?: string;
    defaultFiles?: any[];
    maxFiles?: number;
}

const UPLOAD_MODE = {
    PENDING: "pending",
    SUCCESS: "success",
    ERROR: "error",
};
const DOC = {
    extension: ["docx", "doc", "pptx", "pps"],
    iconFile: "/images/placeholder.png",
};
const EXCEL = {
    extension: ["xls", "csv", "xlsx"],
    iconFile: "/images/placeholder.png",
};
const PDF = { extension: ["pdf"], iconFile: "/images/placeholder.png" };

const IMAGE = {
    extension: [
        "jpeg",
        "bmp",
        "png",
        "jpg",
        "heic",
        "PNG",
        "HEIC",
        "JPG",
        "JPEG",
        "BMP",
    ],
    iconFile: "",
};

const FILE_TYPE = [DOC, EXCEL, PDF];

const FileUploadItem = ({ file, onRemove, onChange, inputParam }: any) => {
    const isPending = file.status === UPLOAD_MODE.PENDING;
    const isError = file.status === UPLOAD_MODE.ERROR;
    const isSuccess = file.status === UPLOAD_MODE.SUCCESS;

    useEffect(() => {
        isPending && onUploadFile();
    }, [file.status]);

    const onRetry = () => {
        onChange({ ...file, status: UPLOAD_MODE.PENDING });
    };

    const onUploadFile = () => {
        MediaAPI.uploadFile(file?.fileData, inputParam?.uploadType)
            .then((res) => {
                onChange({
                    ...file,
                    ...res,
                    status: UPLOAD_MODE.SUCCESS,
                });
            })
            .catch((err) => {
                onChange({ ...file, status: UPLOAD_MODE.ERROR });
            });
    };

    const classLoading = classNames("file-upload__item-loading", {});

    const classIconSuccess = classNames(
        "btn btn-trans file-upload__item-icon text-success"
    );
    const classIconError = classNames(
        "btn btn-trans file-upload__item-icon text-danger"
    );
    const classIconFooter = classNames(
        "btn btn-trans text-white p-1 flex-center"
    );

    const renderItemFooter = () => {
        if (isPending) return <div />;
        return (
            <div className="file-upload__item-footer">
                <button
                    className={classIconFooter}
                    onClick={() => onRemove(file)}
                >
                    <Icon name="delete" size="small" />
                </button>
                {isError && (
                    <button className={classIconFooter} onClick={onRetry}>
                        <Icon name="rotate_left" size="small" />
                    </button>
                )}
            </div>
        );
    };

    return (
        <div className="file-upload__item">
            {isSuccess && (
                <button className={classIconSuccess}>
                    <Icon name="check_circle" />
                </button>
            )}
            {isError && (
                <button className={classIconError}>
                    <Icon name="info" />
                </button>
            )}
            {renderItemFooter()}
            <img src={file.imageData} className="file-upload__item-image" />

            {isPending && (
                <div className={classLoading}>
                    <div className="spinner-border text-white" role="status">
                        <span className="sr-only">Loading...</span>
                    </div>
                </div>
            )}
        </div>
    );
};

const InputFileAreaRef = (
    {
        onChange,
        defaultFiles = [],
        className,
        maxFiles = 0,
        inputParam = {},
    }: IInputFileArea,
    ref: any
) => {
    const listDefaultFile = map(defaultFiles, (item: any) => ({
        ...item,
        status: UPLOAD_MODE.SUCCESS,
    }));

    const [listFile, setListFile] = useState(listDefaultFile);
    const firstTime = useFirstTime();
    useImperativeHandle(ref, () => ({
        refresh: () => {
            setListFile([]);
        },
    }));

    useEffect(() => {
        if (firstTime) return;
        const uploadedFiles = listFile.filter(
            (item: any) => item.status === UPLOAD_MODE.SUCCESS
        );
        onChange(uploadedFiles);
    }, [listFile]);

    const validateFileInput = (fileUpload: any) => {
        if (validateFileLimitNumber(fileUpload)) {
            return true;
        }
        if (validateFileType(fileUpload)) {
            return true;
        }
        return false;
    };
    const validateFileLimitNumber = (fileUpload: any) => {
        if (!!maxFiles && listFile.length + fileUpload.length > maxFiles) {
            Notifications.showError(`Only max ${maxFiles} files!`);
            return true;
        }
        return false;
    };

    /**
     * validate format of file follow inputParam
     * normally, its prevented on mode select but not in drag and drop mode
     * @param {*} fileUpload
     * @returns
     */
    const validateFileType = (fileUpload = []) => {
        if (inputParam?.accept) {
            const acceptTypes = split(inputParam?.accept, ",");
            const isUnValidType = some(
                fileUpload,
                (item: any) => !includes(acceptTypes, item.type)
            );
            if (isUnValidType) {
                Notifications.showError(`Only accept ${inputParam?.accept}`);
                return true;
            }
        }
        return false;
    };

    const getImageDataPreview = (fileName = "", fileData: any) => {
        const extension = StringUtils.getExtensionFromFilename(fileName);
        const isImage = IMAGE.extension.includes(extension);
        if (isImage) return fileData;
        const fileFormat = find(FILE_TYPE, (fileTypeItem) =>
            fileTypeItem.extension.includes(extension)
        );
        if (fileFormat) {
            return fileFormat.iconFile;
        }
        return "";
    };

    function onDrop(fileUpload = []) {
        if (validateFileInput(fileUpload)) {
            return;
        }

        const fileResult: any = [];
        fileUpload.forEach((file: any, index) => {
            const reader = new FileReader();
            const url = reader.readAsDataURL(file);
            reader.onloadend = function (e) {
                fileResult.push({
                    id: StringUtils.getUniqueID(),
                    fileData: file,
                    imageData: getImageDataPreview(file.name, reader.result),
                    status: UPLOAD_MODE.PENDING,
                });
                if (index === fileUpload.length - 1) {
                    const clone = [...fileResult];
                    setListFile([...listFile, ...clone]);
                }
            };
        });
    }

    const { getRootProps, getInputProps, isDragActive } = useDropzone({
        onDrop: onDrop as any,
    });

    const classNameContainer = classNames(
        "p-3 border-dashed d-flex ",
        className
    );

    const onClickRemoveFile = (item: any) => {
        const listFileResult = ObjectUtils.removeArrayById(listFile, item.id);
        setListFile(listFileResult);
    };

    const onChangeFile = (file: any) => {
        const listFileResult = ObjectUtils.updateArrayById(
            listFile as any,
            file
        );
        setListFile(listFileResult);
    };

    return (
        <div className={classNameContainer}>
            {map(listFile, (fileItem: any) => (
                <FileUploadItem
                    file={fileItem}
                    onChange={onChangeFile}
                    onRemove={onClickRemoveFile}
                    inputParam={inputParam}
                />
            ))}
            <div
                className="flex-center flex-column border-dashed file-upload__item"
                {...getRootProps()}
            >
                <input {...getInputProps()} />
                <img
                    src="/images/placeholder.png"
                    className="image-square-x-small"
                />
                <small className="text-center mt-1">{Messages.browse}</small>
            </div>
        </div>
    );
};

const InputFileArea = forwardRef(InputFileAreaRef);
export default InputFileArea;
