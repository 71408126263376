import {
    Button,
    DateInput,
    InputText,
    ObjectUtils,
    StringUtils,
} from "d-react-components";
import { filter, map } from "lodash";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import Messages from "../../../../languages/Messages";

interface IFamilyInfoForm {
    familyInfo: any;
    onChange: any;
    onRemove: any;
    formError?: any;
}

export const CustomerFamilyInfoSchema = Yup.object().shape({
    familyInformation: Yup.array()
        .nullable()
        .of(
            Yup.object().shape({
                name: Yup.string().required("Required field!"),
                relationship: Yup.string().required("Required field!"),
                dateOfBirth: Yup.string().required("Required field!"),
                phone: Yup.string()
                    .min(10, "Phone number is too short!")
                    .max(14, "Phone number is too long!")
                    .required("Required field!"),
            }) as any
        ),
});

const FamilyInfoForm = ({
    familyInfo,
    onChange,
    onRemove,
    formError,
}: IFamilyInfoForm) => {
    const inputClassName = "col-span-12 md:col-span-6";
    const onChangeValue = (key: string, value: any) => {
        onChange({ ...familyInfo, [key]: value });
    };

    return (
        <React.Fragment>
            <div className="grid grid-cols-12 md:gap-4">
                <InputText
                    label={Messages.name}
                    onChange={(event) =>
                        onChangeValue("name", event?.target?.value)
                    }
                    className={inputClassName}
                    value={familyInfo?.name}
                    error={formError?.name ?? null}
                />
                <InputText
                    label={Messages.relationship}
                    onChange={(event) =>
                        onChangeValue("relationship", event?.target?.value)
                    }
                    className={inputClassName}
                    value={familyInfo?.relationship}
                    error={formError?.relationship}
                />

                <DateInput
                    label={Messages.dateOfBirth}
                    onChange={(value) => onChangeValue("dateOfBirth", value)}
                    value={
                        familyInfo?.dateOfBirth
                            ? moment(familyInfo?.dateOfBirth)
                            : (null as any)
                    }
                    format="DD/MM/YYYY"
                    className={inputClassName}
                    error={formError?.dateOfBirth}
                />
                <InputText
                    label={Messages.phone}
                    onChange={(event) =>
                        onChangeValue("phone", event?.target?.value)
                    }
                    className={inputClassName}
                    value={familyInfo?.phone}
                    error={formError?.phone}
                />
            </div>
            <div className="flex w-full justify-end mt-3">
                <Button
                    color="secondary"
                    variant="trans"
                    size="small"
                    onClick={onRemove}
                >
                    {Messages.remove}
                </Button>
            </div>
        </React.Fragment>
    );
};

const CustomerCreateFamilyInfo = ({ customerForm }: any) => {
    const familyInfoList = customerForm?.values?.familyInformation ?? [];
    const familyInfoErrors = customerForm?.errors?.familyInformation ?? [];
    const onUpdateFamilyInformationList = (familyInfo: any) => {
        const result = ObjectUtils.updateArrayById(familyInfoList, familyInfo);
        customerForm.setFieldValue("familyInformation", result);
    };

    const onAddFamilyInfo = () => {
        customerForm.setFieldValue("familyInformation", [
            ...familyInfoList,
            { id: StringUtils.getUniqueID() },
        ]);
    };

    const onRemoveFamilyInfo = (removeData: any) => {
        customerForm.setFieldValue(
            "familyInformation",
            filter(familyInfoList, (i) => i?.id !== removeData?.id)
        );
    };

    return (
        <div className="">
            {map(
                customerForm?.values?.familyInformation,
                (familyInfo, index) => {
                    return (
                        <div className="mt-3">
                            <label className="mb-3 text-primary">{`${
                                Messages.familyInfo
                            } ${index + 1}`}</label>
                            <FamilyInfoForm
                                familyInfo={familyInfo}
                                formError={familyInfoErrors?.[index]}
                                onChange={(value: any) =>
                                    onUpdateFamilyInformationList(value)
                                }
                                onRemove={() => onRemoveFamilyInfo(familyInfo)}
                            />
                        </div>
                    );
                }
            )}
            <div className="w-full flex justify-end mt-3">
                <Button
                    variant="trans"
                    iconName="add"
                    onClick={onAddFamilyInfo}
                >
                    {Messages.addNew}
                </Button>
            </div>
        </div>
    );
};

export default CustomerCreateFamilyInfo;
