import { Button, DateInput, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import { isEmpty, map } from "lodash";
import moment from "moment";
import { useMemo, useState } from "react";
import { StringParam, useQueryParam } from "use-query-params";
import AppointmentAPI from "../../api/appointment/AppointmentAPI";
import BranchSolidSelect from "../../components/branch/BranchSolidSelect";
import Image from "../../components/Image";
import InputTextForm from "../../components/InputTextForm";
import ProductSolidSelect from "../../components/product/ProductSolidSelect";
import { CONTACT_LINKS } from "../../constant/app";
import { AppointmentContext } from "../../context/appointment";
import {
    IAppointment,
    mapAppointmentToServer,
} from "../../interface/appointment";
import Messages from "../../languages/Messages";
import { AppointmentSchema } from "../../schema/appointment";
import AppointmentContact from "./AppointmentContact";

const Appointment = () => {
    const [orderRef] = useQueryParam("orderRef", StringParam);
    const [quotationRef] = useQueryParam("quotationRef", StringParam);
    const [orderId] = useQueryParam("orderId", StringParam);
    const [quotationId] = useQueryParam("quotationId", StringParam);

    const [appointment, setAppointment] = useState<IAppointment>();
    const appointmentRef = useMemo(() => {
        if (orderRef && orderId) {
            return orderRef;
        }
        if (quotationRef && quotationId) {
            return quotationRef;
        }
        return "";
    }, []);

    const appointmentRefType = useMemo(() => {
        if (orderRef && orderId) {
            return "ORDER";
        }
        if (quotationRef && quotationId) {
            return "QUOTATION";
        }
        return null;
    }, []);

    const appointmentRefId = useMemo(() => {
        if (orderRef && orderId) {
            return orderId;
        }
        if (quotationRef && quotationId) {
            return quotationId;
        }
        return null;
    }, []);

    const appointmentForm = useFormik<any>({
        initialValues: {
            ref: appointmentRefType,
            refId: appointmentRefId,
        } as any,
        validateOnChange: false,
        validateOnBlur: false,
        validationSchema: AppointmentSchema,
        onSubmit: (values: any) => {
            const input = mapAppointmentToServer(values);
            onCreateAppointment(input);
        },
    });
    const formValues = appointmentForm?.values;
    const formErrors = appointmentForm?.errors;

    const classNameInput = "col-12 mt-3";

    const onCreateAppointment = (input: any) => {
        Progress.show(
            { method: AppointmentAPI.create, params: [input] },
            (appointmentRes) => {
                Notifications.showSuccess(Messages.createAppointmentSuccess);
                setAppointment(appointmentRes as any);
            }
        );
    };

    if (appointment) {
        return (
            <div className="flex-center flex-column mt-3">
                <div className="flex-center py-5 col-12 flex-column">
                    <h2 className="text-primary">
                        {Messages.customerAppointment}
                    </h2>
                    {!isEmpty(appointmentRef) && (
                        <text>{`${Messages.ref}: ${appointmentRef}`}</text>
                    )}
                </div>
                <Image
                    src="/images/status/success.png"
                    className="image-square-large"
                />
                <div className="w-75 text-center my-5">
                    {Messages.appointmentThankYou}
                    {appointmentRef &&
                        `(${Messages.appointmentRef}: ${appointment?.appointmentNo})`}
                </div>
                <div className="d-flex">
                    {map(CONTACT_LINKS, (link) => (
                        <a href={link.url}>
                            <Image
                                src={link.icon}
                                className="image-square-medium text-primary mx-4 mx-md-5"
                            />
                        </a>
                    ))}
                </div>
            </div>
        );
    }

    return (
        <AppointmentContext.Provider
            value={{
                appointmentForm,
                appointmentRef,
            }}
        >
            <div className="bg-white row p-4">
                <div className="flex-center py-5 col-12 flex-column">
                    <h2 className="text-primary">
                        {Messages.customerAppointment}
                    </h2>
                    {!isEmpty(appointmentRef) && (
                        <text>{`${Messages.ref}: ${appointmentRef}`}</text>
                    )}
                </div>
                <AppointmentContact />
                <BranchSolidSelect
                    className={classNameInput}
                    value={formValues?.branch}
                    error={formErrors?.branch}
                    onChange={(value) =>
                        appointmentForm.setFieldValue("branch", value)
                    }
                />

                <ProductSolidSelect
                    multiple
                    className={classNameInput}
                    value={formValues?.products}
                    error={formErrors?.products}
                    onChange={(value) =>
                        appointmentForm.setFieldValue("products", value)
                    }
                />

                <DateInput
                    label={Messages.estDateVisit}
                    onChange={(val) =>
                        appointmentForm.setFieldValue(
                            "date",
                            moment(val).toISOString()
                        )
                    }
                    value={
                        formValues.date
                            ? moment(formValues.date)
                            : (null as any)
                    }
                    className={classNameInput}
                    error={formErrors.date as any}
                    required
                />

                <DateInput
                    className={classNameInput}
                    format="HH:mm"
                    type="time"
                    label={Messages.estTimeVisit}
                    value={formValues?.time}
                    error={formErrors?.time as string}
                    onChange={(value) =>
                        appointmentForm.setFieldValue("time", value)
                    }
                    isRangePicker
                />

                <InputTextForm
                    form={appointmentForm}
                    keyData="remark"
                    multiple
                    className={classNameInput}
                />
                <div className="col-12 mt-5 flex-row">
                    <Button
                        onClick={() => {}}
                        variant="outline"
                        className="flex-grow-1 mr-3"
                    >
                        {Messages.doNotWantToBookNow}
                    </Button>
                    <Button
                        onClick={() => appointmentForm.handleSubmit()}
                        className="flex-grow-1"
                    >
                        {Messages.confirmAppointment}
                    </Button>
                </div>
            </div>
        </AppointmentContext.Provider>
    );
};

export default Appointment;
