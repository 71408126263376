export default {
    invoiceNo: "Invoice No.",
    createdAt: "Created At",
    expireAt: "Expire At",
    orderStatus: "Order Status",
    itemName: "Item name",
    unitPrice: "Unit Price",
    quantity: "Quantity",
    price: "Price",
    subTotal: "Subtotal",
    total: "Total",
    orderContact: "Order contact",
    edit: "Edit",
    orderContactDesc:
        "Please note that we will send the order confirmation as well as all other order activities to this email. You can skip it if you don't have any email.",
    confirmEmail: "Confirm email",
    phone: "Phone No.",
    confirmPhone: "Confirm phone No.",
    firstName: "First Name",
    lastName: "Last Name",
    email: "Email",
    save: "Save",
    cancel: "Cancel",
    shippingInformation: "Shipping Information",
    nickName: "Nick Name",
    province: "Province",
    district: "District/City",
    subDistrict: "Sub-district",
    postCode: "Postcode",
    address: "Address",
    typeOfPlace: "Type of place",
    nameOfPlace: "Name of place",
    partiallyPaid: "Partially Paid",
    processing: "Processing",
    orderProcessing: "Order Processing",
    deliveryProcessing: "Delivery Processing",
    delivered: "Delivered",
    completed: "Completed",
    refunded: "Refunded",
    cancelled: "Cancelled",
    expired: "Expired",
    sku: "SKU",
    transportationFee: "Transportation fee",
    phoneNotMatch: "Confirm phone not match",
    emailNotMatch: "Confirm email not match",
    updateOrderContactSuccess: "Update order contact successfully!",
    shippingAddress: "Shipping address",
    country: "Country",
    postcode: "Postcode",
    condominium: "Condominium/Apartment",
    privateHouse: "Private House",
    companyOffice: "Company/Office",
    "condo-apartment": "Condominium/Apartment",
    "private-house": "Private House",
    "company-office": "Company/Office",
    updateShippingAddressSuccess: "Update shipping address successfully!",
    payment: "Payment",
    amount: "Amount",
    paymentRef: "Payment Ref",
    paymentMethod: "Payment method",
    paySecurelyByUsing2C2P:
        "Pay securely by using 2C2P. Click proceed to be redirected to 2C2P system to complete the payment.",
    proceed: "Proceed",
    paymentShouldBeMade: "Payments should be made before",
    onceYouMadeThePayment:
        "Once you made the payment, please attach the receipt of the payment below",
    receiptOfPayment: "Receipt of payment (Bank Slip)",
    dragAndDrop: "Drag and drop file here (Maximum 2 files per upload)",
    or: "-- or --",
    browse: "Browse",
    dateOfTransfer: "Date of transfer",
    fullNameTransferee: "Name Of Payor",
    bankTransferTo: "Bank transferred to",
    cantChooseFutureDate: "Can not choose future date",
    uploadProofPaymentSuccess: "Upload proof payment successfully!",
    uploadedAt: "Upload at",
    nameOfPayer: "Name of payer",
    bankOfTransfer: "Bank transferred to",
    transaction: "Transaction",
    dateOfPayment: "Date of payment",
    pendingConfirmation: "Pending Confirmation",
    pending: "Pending",
    processingMessage:
        "Thank you very much. Your order has been successfully paid and confirmed. Once your order is ready for delivery, we will update the tracking code via your email. Should you have any question, please contact our customer support via channels below",
    cancelledMessage:
        "Sorry! Your order has been cancelled. Should you have any question, please contact our customer support via channels below",
    deliveredMessage:
        "Your order has been successfully delivered. Should you have any question, please contact our customer support via channels below",
    completedMessage:
        "Your order has been completed. Should you have any question, please contact our customer support via channels below",
    yourOrderPartiallyPaid:
        "Thank you very much for your payment. Your order is partially paid and confirmed. Should you have any question, please contact our customer support via channels below",
    yourOrderNowProcess: "Your order is now being processed for the delivery.",
    contactOurSupport:
        "Should you have any question, please contact our customer support via channels below",
    list: "List",
    summary: "Summary",
    items: "Items",
    updateContact: "Update contact",
    updateShippingAddress: "Update shipping address",
    quotationNo: "Quotation No",
    orderNo: "Order No",
    status: "Status",
    active: "Active",
    step: "Step",
    submit: "Submit",
    contactInformation: "Contact Information",
    orderPaid:
        "Thank you very much. Your order has been successfully paid and confirmed. Our staff will reach out to you soon. Should you have any question, please contact our customer support via channels below",
    taxIncluded: "Tax (7% Included)",
    customerAppointment: "Customer appointment",
    ref: "Ref",
    branch: "Branch",
    pleaseSelect: "Please select",
    code: "Code",
    product: "Product",
    pleaseSearchAndSelect: "Please search and select",
    estDateVisit: "Est. Date Of Visit",
    estTimeVisit: "Est. Time Of Visit",
    remark: "Remark",
    doNotWantToBookNow: "I do not want to book now",
    confirmAppointment: "Confirm appointment",
    createAppointmentSuccess: "Create appointment successfully!",
    appointmentThankYou:
        "Thank you very much. Your appointment has been successfully submitted and now under review. We will check and confirm your appointment via phone number/email as soon as possible. In the meantime, should you have any question, please do not hesitate to contact our customer support using your appointment reference via channels below",
    appointmentRef: "Appointment ref",
    pendingPaymentConfirmation:
        "Thank you very much for submitting the receipt of payment. Your bank transfer information is now pending for the confirmation from our accounting department. Should you have any question, please contact our customer support via channels below",
    paymentRejected:
        "Sorry, you payment has been rejected. Please try again or contact our customer support via channels below",
    newCustomer: "New customer",
    branchWarehouse: "Branch/Warehouse",
    emergencyContact: "Emergency contact",
    personalInfo: "Personal information",
    familyInfo: "Family information",
    contactInfo: "Contact information",
    privacyInfo: "Privacy information",
    title: "Title",
    firstNameTh: "First Name (Thai)",
    firstNameEn: "First Name (Eng)",
    lastNameTh: "Last Name (Thai)",
    lastNameEn: "Last Name (Eng)",
    username: "User Name",
    dateOfBirth: "Date of birth",
    addMore: "Add more",
    preferNotToSay: "Prefer not to say",
    male: "Male",
    female: "Female",
    nickname: "Nickname",
    gender: "Gender",
    passportExp: "Passport Exp.",
    citizenId: "Citizen Id",
    nationality: "Nationality",
    passportNo: "Passport No.",
    religion: "Religion",
    maritalStatus: "Marital Status",
    numberOfChild: "Number of Child",
    primaryPhone: "Primary Phone No.",
    alternativePhoneNo: "Alternative Phone No.",
    alternativePhone: "Alternative Phone No.",
    facebook: "Facebook",
    instagram: "Instagram",
    line: "Line",
    google: "Google",
    lineId: "Line ID",
    addNew: "Add new",
    relationship: "Relationship",
    currentAddress: "Current address",
    upload: "Upload",
    name: "Name",
    signature: "Signature",
    clear: "Clear",
    getStart: "Get Started",
    secretCode: "Secret Code",
    personalInfoShort: "Personal Info",
    privacyInfoShort: "Privacy Info",
    contactInfoShort: "Contact Info",
    familyInfoShort: "Family Info",
    bookAppointment: "Book Appointment",
    clientSignature: "Client's Signature",
    witnessSignature: "Witness's Signature",
    listAnyAllergies: "List any allergies to medications",
    listTakingMedications: "List all medications you are taking",
    listPreviousSurgery: "List previous major surgery & illnesses with date",
    significantFamilyHistory: "Significant family history",
    newPatientRegistration: "New Patient Online Registration",
    healthSelfAssessmentForm: "Health Self-Assessment Form",
    healthSelfAssessmentFormDesc:
        "At Dermaster, we prioritize providing a hassle-free and effective registration process for our patients. Based on the feedback we received from our patients, we have recently introduced a new online health self-assessment form to simplify and streamline the registration process.",
    healthSelfAssessmentFormDesc2:
        "Kindly ensure that you provide precise and comprehensive details while completing this form. Rest assured that any information you submit through this online pre-registration form will be treated by Dermaster with utmost confidentiality and will be considered as a part of your patient record.",
    inOrderToStartRegistration:
        "In order to start your registration, please verify your phone number first.Please input the 6-digit verification code sent to your phone number",
    registrationVerifyDescription:
        "At Dermaster, we prioritize providing a hassle-free and effective registration process for our patients. Based on the feedback we received from our patients, we have recently introduced a new online patient pre-registration portal to simplify and streamline the registration process.",
    registrationVerifyDescription2:
        "Kindly ensure that you provide precise and comprehensive details while completing this form. Rest assured that any information you submit through this online pre-registration form will be treated by Dermaster with utmost confidentiality and will be considered as a part of your patient record.",
    healthAssessmentConfirmation:
        "I hereby acknowledge and confirm all the information that I declared above is accurate. I’m fully responsible for these information that I provided.",
    didNotReceiveTheCode: "Did not receive the code?",
    resendNow: "Resend now",
    phoneNumberVerification: "Phone Number Verification",
    verify: "Verify",
    residenceAddress: "Residence Address",
    codeIsInvalid: "Code is invalid !",
    backToHome: "Back to home",
    yourRefIs: "Your ref. is $ref",
    confirm: "Confirm",
    remove: "Remove",
    confirmHealthSubmit:
        "I hereby acknowledge and confirm all the information that I declared above is accurate. I’m fully responsible for these information that I provided.",
    submitFormSuccess:
        "Thank you very much. Your application is successfully submitted and will be shortly reviewed by our relevant department. A confirmation email is already sent to your email for your information (in case you have provided email in the registration). ",
    manualDiscount: "Manual Discount",
    taxId: "Tax ID",
};
