import React, { Dispatch } from "react";
import {
    IRegistrationAuthState,
    IRegistrationFormValueState,
} from "../Registration";

interface IRegistrationContextState {
    isUpdateInfo: boolean;
    customerId?: string;
    authState: IRegistrationAuthState;
    setAuthState: Dispatch<IRegistrationAuthState>;
    formValueState?: IRegistrationFormValueState;
    setFormValueState: Dispatch<IRegistrationFormValueState>;
}

const registrationContextState: IRegistrationContextState = {
    isUpdateInfo: false,
    authState: {},
    setAuthState: () => {},
    formValueState: {},
    setFormValueState: () => {},
};

export const RegistrationContext = React.createContext(
    registrationContextState
);
