import { isEmpty } from "lodash";
import * as Yup from "yup";

export const AppointmentSchema = Yup.lazy((value: any) => {
    const schema = Yup.object().shape({
        branch: Yup.object().required("Required field!"),
        products: Yup.array().required("Required field!"),
        date: Yup.string().required("Required field!"),
        time: Yup.array().required("Required field!"),
        // remark: Yup.string().required("Required field!"),
    });
    if (isEmpty(value.ref) && isEmpty(value.RefId)) {
        return schema.concat(
            Yup.object().shape({
                contact: Yup.object().shape({
                    firstName: Yup.string().required("Required field!"),
                    lastName: Yup.string().required("Required field!"),
                    phone: Yup.string().required("Required field!"),
                    email: Yup.string()
                        .email("Not email format!")
                        .required("Required field!"),
                }),
            }) as any
        );
    }

    return schema;
});
