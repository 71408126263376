import { gql } from "@apollo/client";

export const F_ORDER_DISCOUNT = gql`
    fragment F_ORDER_DISCOUNT on Discount {
        code
        discount
    }
`;

export const F_ADDRESS = gql`
    fragment F_ADDRESS on Address {
        id
        firstName
        lastName
        nickName
        gender
        phone
        country
        province {
            id
            name
        }
        district {
            id
            name
        }
        subDistrict {
            id
            name
        }
        postcode
        address
        typeOfPlace
        nameOfPlace
        pin
        remark
    }
`;

export const F_PAYMENT_METHOD = gql`
    fragment F_PAYMENT_METHOD on PaymentMethod {
        id
        th {
            title
            description
        }
        en {
            title
            description
        }
        type
        priority
        status
        minimum
        maximum
        bankAccounts {
            id
            image
            th {
                bankName
                accountName
            }
            en {
                bankName
                accountName
            }
            accountNo
        }
        updatedAt
    }
`;

export const F_ORDER_PRODUCT = gql`
    fragment F_ORDER_PRODUCT on CustomerOrderProduct {
        quantity
        regularPrice
        hasOpeningPrice
        salePrice
        manualProduct
        typeOfProduct
        product {
            id
            name
            productNo
            sku
            status
            unit
            thumbnail
            gallery
        }
        groups {
            quantity
            salePrice
            isDynamicPrice
            product {
                id
                name
                productNo
                status
                sku
                unit
                gallery
            }
        }
    }
`;

export const F_ORDER_MANUAL_PRODUCT = gql`
    fragment F_ORDER_MANUAL_PRODUCT on CustomerOrderManualProduct {
        quantity
        salePrice
        name
        remark
        sku
    }
`;

export const F_ORDER = gql`
    ${F_ORDER_PRODUCT}
    ${F_ORDER_MANUAL_PRODUCT}
    fragment F_ORDER on CustomerOrder {
        id
        orderNo
        status
        typeOfTreatment {
            id
            name
        }
        purchaseType
        subtotal
        total
        totalVat
        createdAt
        updatedAt
        expired
        customer {
            avatar
            firstNameTh
            lastNameTh
            firstNameEn
            lastNameEn
            id
            nickname
            phone
            email
            gender
        }
        contact {
            firstName
            lastName
            email
            phone
        }
        store {
            id
            name
        }
        fee {
            name
            total
        }
        products {
            ...F_ORDER_PRODUCT
        }
        manualProduct {
            ...F_ORDER_MANUAL_PRODUCT
        }
    }
`;
