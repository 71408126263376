import {
    Button,
    InputText,
    ObjectUtils,
    StringUtils,
} from "d-react-components";
import { filter, map } from "lodash";
import React from "react";
import * as Yup from "yup";
import Messages from "../../../../languages/Messages";

interface IEmergencyContactForm {
    contact: any;
    onChange: any;
    onRemove: any;
    formError?: any;
}

export const CustomerEmergencyContactSchema = Yup.object().shape({
    emergencyContact: Yup.array()
        .of(
            Yup.object().shape({
                name: Yup.string().required("Required field!"),
                relationship: Yup.string().required("Required field!"),
                residence: Yup.string().required("Required field!"),
                phone: Yup.string()
                    .min(10, "Phone number is too short!")
                    .max(14, "Phone number is too long!")
                    .required("Required field!"),
            }) as any
        )
        .nullable(),
});

const EmergencyContactForm = ({
    contact,
    onChange,
    onRemove,
    formError,
}: IEmergencyContactForm) => {
    const inputClassName = "col-6 mt-3";
    const onChangeValue = (key: string, value: any) => {
        onChange({ ...contact, [key]: value });
    };

    return (
        <React.Fragment>
            <div className="row">
                <InputText
                    label={Messages.name}
                    onChange={(event) =>
                        onChangeValue("name", event?.target?.value)
                    }
                    className={inputClassName}
                    value={contact?.name}
                    error={formError?.name}
                />
                <InputText
                    label={Messages.relationship}
                    onChange={(event) =>
                        onChangeValue("relationship", event?.target?.value)
                    }
                    className={inputClassName}
                    value={contact?.relationship}
                    error={formError?.relationship}
                />

                <InputText
                    label={Messages.currentAddress}
                    onChange={(event) =>
                        onChangeValue("residence", event?.target?.value)
                    }
                    className={inputClassName}
                    value={contact?.residence}
                    error={formError?.residence}
                />
                <InputText
                    label={Messages.phone}
                    onChange={(event) =>
                        onChangeValue("phone", event?.target?.value)
                    }
                    className={inputClassName}
                    value={contact?.phone}
                    error={formError?.phone}
                />
            </div>
            <div className="flex w-full justify-end mt-3">
                <Button
                    color="secondary"
                    variant="trans"
                    size="small"
                    onClick={onRemove}
                >
                    {Messages.remove}
                </Button>
            </div>
        </React.Fragment>
    );
};

const CustomerCreateEmergency = ({ customerForm }: any) => {
    const emergencyContactList = customerForm?.values?.emergencyContact ?? [];
    const emergencyContactErrors = customerForm?.errors?.emergencyContact ?? [];
    const onUpdateEmergencyContactList = (contact: any) => {
        const result = ObjectUtils.updateArrayById(
            emergencyContactList,
            contact
        );

        customerForm.setFieldValue("emergencyContact", result);
    };

    const onAddContact = () => {
        customerForm.setFieldValue("emergencyContact", [
            ...emergencyContactList,
            { id: StringUtils.getUniqueID() },
        ]);
    };

    const onRemoveContact = (removeData: any) => {
        customerForm.setFieldValue(
            "emergencyContact",
            filter(emergencyContactList, (i) => i?.id !== removeData?.id)
        );
    };

    return (
        <div className="">
            {map(emergencyContactList, (contact, index) => {
                return (
                    <div className="mt-3">
                        <label>{`${Messages.contactInfo} ${index + 1}`}</label>
                        <EmergencyContactForm
                            contact={contact}
                            formError={emergencyContactErrors?.[index]}
                            onChange={(value: any) =>
                                onUpdateEmergencyContactList(value)
                            }
                            onRemove={() => onRemoveContact(contact)}
                        />
                    </div>
                );
            })}
            <div className="w-full flex justify-end mt-3">
                <Button variant="trans" iconName="add" onClick={onAddContact}>
                    {Messages.addNew}
                </Button>
            </div>
        </div>
    );
};

export default CustomerCreateEmergency;
