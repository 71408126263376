import React from "react";

export interface IAppState {
    appointmentForm: any;
    appointmentRef: string | null;
}

export const appStateDefault: IAppState = {
    appointmentForm: {} as any,
    appointmentRef: "",
};

export const AppointmentContext = React.createContext(appStateDefault);
