import { gql } from "@apollo/client";

export const F_QUOTATION_MANUAL_PRODUCT = gql`
    fragment F_QUOTATION_MANUAL_PRODUCT on CustomerQuotationManualProduct {
        quantity
        salePrice
        name
        remark
        sku
    }
`;

export const F_QUOTATION = gql`
    ${F_QUOTATION_MANUAL_PRODUCT}
    fragment F_QUOTATION on CustomerQuotation {
        id
        quotationNo
        status
        typeOfTreatment {
            id
            name
        }
        purchaseType
        subtotal
        total
        totalVat
        createdAt
        updatedAt
        expired
        customer {
            avatar
            firstNameTh
            lastNameTh
            firstNameEn
            lastNameEn
            id
            nickname
            phone
            email
            gender
        }
        contact {
            firstName
            lastName
            email
            phone
        }
        store {
            id
            name
        }
        fee {
            name
            total
        }
        products {
            quantity
            regularPrice
            salePrice
            product {
                id
                name
                productNo
                sku
                status
                unit
                thumbnail
                gallery
            }
        }
        manualProduct {
            ...F_QUOTATION_MANUAL_PRODUCT
        }
        manualDiscount {
            discountType
            discountValue
            total
            product {
                id
                name
                sku
            }
        }
    }
`;
