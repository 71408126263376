import React from "react";
import { Button, Icon } from "d-react-components";
import Messages from "../../../../languages/Messages";
import Path from "../../../Path";

const SubmitSuccess = ({ refCode = "0000" }: { refCode?: any }) => {
    return (
        <div
            className={`container py-5 text-center mt-44 `}
            style={{ maxWidth: "600px", fontSize: "12px" }}
        >
            <Icon name="check_circle" color="green" size="xxx-large" />
            <p className="text mt-10">{Messages.submitFormSuccess}</p>
            <div className="text my-4">
                {Messages.yourRefIs?.replace("$ref", `#${refCode}`)}
            </div>
            {/* <a href={Path.ROOT}>
                <Button className="m-auto">{Messages.backToHome}</Button>
            </a> */}
        </div>
    );
};

export default SubmitSuccess;
