import ContactInfoForm, { ContactSchema } from "./shared/ContactInfoForm";
import PersonalInformationForm, {
    PersonalInfoSchema,
} from "./shared/PersonalInfoForm";
import PrivacyInfoForm, { PrivacyInfoSchema } from "./shared/PrivacyInfoForm";
import CustomerCreateFamilyInfo, {
    CustomerFamilyInfoSchema,
} from "./shared/FamilyInfoForm";
import SelectBranchForm, {
    SelectBranchFormSchema,
} from "./shared/SelectBranchForm";
import CustomerCreateEmergency, {
    CustomerEmergencyContactSchema,
} from "./shared/EmergencyContactForm";
import ResidenceAddressForm, {
    ResidenceAddressSchema,
} from "./shared/ResidenceAddressForm";

const REGISTRATION_FORM_DATA = [
    {
        id: "branchWarehouse",
        label: "branch",
        mobileLabel: "branch",
        //@ts-ignore
        component: <SelectBranchForm />,
        schema: SelectBranchFormSchema,
    },

    {
        id: "personalInfo",
        label: "personalInfo",
        mobileLabel: "personalInfoShort",
        //@ts-ignore
        component: <PersonalInformationForm />,
        schema: PersonalInfoSchema,
    },
    {
        id: "privacyInfo",
        label: "privacyInfo",
        mobileLabel: "privacyInfoShort",
        //@ts-ignore
        component: <PrivacyInfoForm />,
        schema: PrivacyInfoSchema,
    },
    {
        id: "contactInfo",
        label: "contactInfo",
        mobileLabel: "contactInfoShort",
        //@ts-ignore
        component: <ContactInfoForm />,
        schema: ContactSchema,
    },
    {
        id: "residenceAddress",
        label: "residenceAddress",
        mobileLabel: "residenceAddress",
        //@ts-ignore
        component: <ResidenceAddressForm />,
        schema: ResidenceAddressSchema,
    },
    {
        id: "familyInfo",
        label: "familyInfo",
        mobileLabel: "familyInfoShort",
        //@ts-ignore
        component: <CustomerCreateFamilyInfo />,
        schema: CustomerFamilyInfoSchema,
    },
    {
        id: "emergencyContact",
        label: "emergencyContact",
        mobileLabel: "emergencyContact",
        //@ts-ignore
        component: <CustomerCreateEmergency />,
        schema: CustomerEmergencyContactSchema,
    },
];

export default REGISTRATION_FORM_DATA;
