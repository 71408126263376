import { gql } from "@apollo/client";

export const F_APPOINTMENT = gql`
    fragment F_APPOINTMENT on CustomerAppointmentDto {
        id
        updatedAt
        createdAt
        day
        startDate
        endDate
        scheduleDate
        schedule {
            start
            end
        }
        appointmentNo
        status
        remark
        branch {
            id
            name
            code
        }
        services {
            id
            name
            productNo
            status
            sku
            unit
            thumbnail
            gallery
            description
        }

        contact {
            firstName
            lastName
            email
            phone
        }

        order {
            id
            orderNo
            status
            subtotal
            total
        }
        quotation {
            id
            quotationNo
            status
            subtotal
            total
        }
    }
`;
