export const QUOTATION_STATUS = {
  ACTIVE: "ACTIVE",
  CANCELLED: "CANCELLED",
};

export const QUOTATION_STATUSES = [
  {
    id: QUOTATION_STATUS.ACTIVE,
    label: "active",
    color: "#33B950",
  },
  {
    id: QUOTATION_STATUS.CANCELLED,
    label: "cancelled",
    color: "#ED6969",
  },
];
