import { gql } from "@apollo/client";
import API from "../API";
import axios from "axios";

interface IUploadParam {
    file?: any;
    base64?: string;
}

const getUploadUrl = gql`
    query getPublicUrlUploadForCustomer(
        $name: String!
        $uploadType: UploadType!
    ) {
        data: getPublicUrlUploadForCustomer(
            name: $name
            uploadType: $uploadType
        ) {
            data {
                uploadUrl
                fileName
                fileUrl
                status
            }
        }
    }
`;

export default {
    uploadMedia: ({ file, base64 }: IUploadParam) =>
        API.instanceUpload.mutate({
            mutation: gql`
                mutation ($file: Upload, $base64: String) {
                    upload(file: $file, base64: $base64) {
                        url
                    }
                }
            `,
            variables: { file, base64 },
        }),

    uploadFile: async (fileData: any, uploadType: string) => {
        try {
            const dataUrlUpload = await API.query({
                query: getUploadUrl,
                fetchPolicy: "no-cache",
                variables: {
                    name: fileData?.name,
                    uploadType,
                },
            });
            const { uploadUrl, fileUrl, fileName } =
                dataUrlUpload?.data?.data?.data ?? {};

            const uploaded = await axios.put(uploadUrl as any, fileData);
            return { fileUrl, fileName };
        } catch (error) {
            return Promise.reject(error);
        }
    },
};
