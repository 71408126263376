import { DateInput, Form, IFormItemData } from "d-react-components";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import InputSelectForm from "../../../../components/InputSelectForm";
import InputTextForm from "../../../../components/InputTextForm";
import UploadAvatar from "../../../../components/upload/UploadAvatar";
import { CUSTOMER_TITLES, GENDERS } from "../../../../constant/customer";
import Messages from "../../../../languages/Messages";

export interface IPersonalInfoFormProps {
    customerForm: any;
}

export const PersonalInfoSchema = Yup.object().shape({
    // avatar: Yup.string().required("Required"),
    title: Yup.string().required("Required"),
    firstNameTh: Yup.string().required("Required"),
    firstNameEn: Yup.string().required("Required"),
    lastNameTh: Yup.string().required("Required"),
    lastNameEn: Yup.string().required("Required"),
    nickname: Yup.string().required("Required"),
    gender: Yup.string().required("Required"),
});

const PersonalInfoForm: React.FC<IPersonalInfoFormProps> = ({
    customerForm,
}) => {
    const inputClassName = "col-span-12 md:col-span-6 mt-3";
    const formValues = customerForm?.values;
    const formErrors = customerForm?.errors;
    return (
        <div className="grid grid-flow-row md:gap-4">
            <div className="col-span-12 flex md:w-full justify-center md:justify-start my-4">
                <label>{Messages.profilePicture}</label>
                <UploadAvatar
                    value={formValues?.avatar}
                    onChange={(file: any) => {
                        customerForm.setFieldValue("avatar", file?.fileUrl);
                    }}
                />
            </div>
            <InputSelectForm
                keyData="title"
                form={customerForm}
                dataSource={CUSTOMER_TITLES}
                getLabel={(item: any) => item.label}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="firstNameTh"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="lastNameTh"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="firstNameEn"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="lastNameEn"
                form={customerForm}
                className={inputClassName}
                required
            />
            <InputTextForm
                keyData="nickname"
                form={customerForm}
                className={inputClassName}
                required
            />
            <DateInput
                label={Messages.dateOfBirth}
                onChange={(value) =>
                    customerForm.setFieldValue("birthDay", value)
                }
                value={
                    formValues.birthDay
                        ? moment(formValues.birthDay)
                        : (null as any)
                }
                error={formErrors.birthDay}
                format="DD/MM/YYYY"
                className={inputClassName}
            />
            <InputSelectForm
                keyData="gender"
                form={customerForm}
                dataSource={GENDERS}
                className={inputClassName}
                required
            />
        </div>
    );
};

export default PersonalInfoForm;
