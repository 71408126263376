import { Progress } from "d-react-components";
import { isEmpty, sortBy } from "lodash";
import React, { useEffect, useState } from "react";
import { useParams } from "react-router";
import AddressAPI from "../../api/address/AddressAPI";
import PaymentAPI from "../../api/payment/PaymentAPI";
import QuotationAPI from "../../api/quotation/QuotationAPI";
import { PaymentMethod } from "../../constant/payment";
import { QuotationContext } from "../../context/quotation";
import { IPaymentMethod } from "../../interface/paymentMethod";
import { IQuotation } from "../../interface/quotation";
import OrderContact from "./QuotationContact";
import OrderGeneralInfo from "./QuotationGeneralInfo";
import OrderPayment from "./QuotationPayment";
import OrderProductsDesktop from "./QuotationProductsDesktop";
import OrderProductsMobile from "./QuotationProductsMobile";
import OrderShippingAddress from "./QuotationShippingAddress";
import BranchAPI from "../../api/branch/BranchAPI";
import { IBranch } from "../../interface/branch";

const Quotation = () => {
    const { id } = useParams();
    const [branch, setBranch] = useState<IBranch>();
    const [quotationDetail, setQuotationDetail] = useState<IQuotation>();
    const [shippings, setShippings] = useState<any[]>([]);
    const [splitPayment, setSplitPayment] = useState<any[]>([]);
    const [paymentMethods, setPaymentMethods] = useState<IPaymentMethod[]>([]);
    const [countryList, setCountryList] = useState<any[]>([]);

    useEffect(() => {
        loadQuotationDetail();
        loadShippingList();
        loadCountryList();
        loadPaymentList();
        loadPaymentMethodList();
    }, []);

    const loadQuotationDetail = () => {
        if (isEmpty(id)) return;

        Progress.show(
            { method: QuotationAPI.detail, params: [id] },
            setQuotationDetail as any
        );
    };

    const loadShippingList = () => {
        AddressAPI.addressList(id as string).then((resp) => {
            setShippings(resp?.data?.listAllShippingForCustomer?.data);
        });
    };

    const loadCountryList = () => {
        AddressAPI.countryList({
            page: 1,
            limit: 10000,
        }).then((resp) => {
            setCountryList(sortBy(resp, "name"));
        });
    };

    const loadPaymentList = () => {
        QuotationAPI.loadPayments(id as string).then((resp) => {
            setSplitPayment(resp);
        });
    };

    const loadPaymentMethodList = async () => {
        const listPaymentMethod = await PaymentAPI.paymentMethodList();
        const listPayment2C2P = await PaymentAPI.payment2C2PList();

        setPaymentMethods([
            ...listPaymentMethod
                .filter(
                    (payment: any) =>
                        payment.paymentConfigurationType !==
                        PaymentMethod.PAYMENT_2C2P
                )
                .map((payment: any) => ({
                    ...payment,
                    key: `${payment.paymentConfigurationType}`,
                })),
            ...listPayment2C2P.map((payment: any) => ({
                ...payment,
                paymentConfigurationType: PaymentMethod.PAYMENT_2C2P,
                key: `${PaymentMethod.PAYMENT_2C2P}_${payment.option}`,
            })),
        ]);
    };

    useEffect(() => {
        if (quotationDetail?.store?.id) {
            BranchAPI.detail(quotationDetail?.store?.id).then((resp) => {
                setBranch(resp?.data?.data?.data);
            });
        }
    }, [quotationDetail?.store?.id]);

    return (
        <QuotationContext.Provider
            value={{
                quotationDetail: quotationDetail as IQuotation,
                setQuotationDetail,
                shippings,
                loadShippingList,
                countryList,
                splitPayment,
                paymentMethods,
                loadPaymentList,
                branch,
            }}
        >
            {quotationDetail && (
                <>
                    <OrderGeneralInfo />
                    <OrderProductsDesktop />
                    <OrderProductsMobile />
                    <OrderContact />
                    <OrderShippingAddress />
                    <OrderPayment />
                </>
            )}
        </QuotationContext.Provider>
    );
};

export default Quotation;
