import { ApolloProvider } from "@apollo/client";
import React from "react";
import ReactDOM from "react-dom";
import API from "./api/API";
import Root from "./Root";
import "./style/tailwind.css";
import "./style/index.scss";

ReactDOM.render(
    <ApolloProvider client={API.instance}>
        <Root />
    </ApolloProvider>,
    document.getElementById("root")
);
