import React, { Fragment } from "react";
import { isEmpty, map } from "lodash";
import CurrencyFormat from "react-currency-format";
import classNames from "classnames";
import { DiscountType, IOrderManualDiscount } from "../../interface/order";
import Messages from "../../languages/Messages";

const ManualDiscount = ({
    manualDiscount,
    isMobile = false,
}: {
    manualDiscount: IOrderManualDiscount;
    isMobile?: boolean;
}) => {
    if (isEmpty(manualDiscount)) {
        return null;
    }
    return (
        <Fragment>
            {map(manualDiscount, (item, index) => {
                const { discountType, discountValue, product, total } =
                    item || {};
                const percentageManualDiscount = discountValue;
                if (isMobile) {
                    return (
                        <div key={index} className="flex w-100">
                            <div className="flex-1">
                                <div className="font-semibold text-sm">
                                    {Messages.manualDiscount}
                                </div>
                                <div className="text-primary text-sm">
                                    {`( ${
                                        product
                                            ? `SKU:${product?.sku}`
                                            : Messages.wholeCart
                                    } )`}
                                </div>
                            </div>
                            <div className="text-right text-green-500">
                                <>
                                    <CurrencyFormat
                                        className="text-success"
                                        displayType="text"
                                        thousandSeparator
                                        prefix="- ฿"
                                        value={total}
                                        decimalScale={2}
                                        fixedDecimalScale
                                    />
                                    {discountType ===
                                        DiscountType.Percentage && (
                                        <span className="text-success">
                                            (
                                            <CurrencyFormat
                                                displayType="text"
                                                thousandSeparator
                                                value={percentageManualDiscount}
                                                decimalScale={2}
                                                fixedDecimalScale
                                            />
                                            %)
                                        </span>
                                    )}
                                </>
                            </div>
                        </div>
                    );
                }
                return (
                    <div key={index} className="grid grid-cols-2 py-3 border-b">
                        <div className="col-span-1">
                            <div className="flex items-center">
                                <div>{Messages.manualDiscount}</div>
                            </div>
                            <div className="text-primary">
                                {`( ${
                                    product
                                        ? `SKU:${product?.sku}`
                                        : Messages.wholeCart
                                } )`}
                            </div>
                        </div>
                        <div
                            className={classNames(
                                `col-span-1 text-right text-green-500 pr-2`
                            )}
                        >
                            <>
                                <CurrencyFormat
                                    className="text-success"
                                    displayType="text"
                                    thousandSeparator
                                    prefix="- ฿"
                                    value={total}
                                    decimalScale={2}
                                    fixedDecimalScale
                                />
                                {discountType === DiscountType.Percentage && (
                                    <span className="text-success">
                                        (
                                        <CurrencyFormat
                                            displayType="text"
                                            thousandSeparator
                                            value={percentageManualDiscount}
                                            decimalScale={2}
                                            fixedDecimalScale
                                        />
                                        %)
                                    </span>
                                )}
                            </>
                        </div>
                    </div>
                );
            })}
        </Fragment>
    );
};

export default ManualDiscount;
