import { DateInput, Form, IFormItemData } from "d-react-components";
import moment from "moment";
import React from "react";
import * as Yup from "yup";
import InputSelectForm from "../../../../components/InputSelectForm";
import InputTextForm from "../../../../components/InputTextForm";
import { MARITAL_STATUS, RELIGIONS } from "../../../../constant/customer";
import Messages from "../../../../languages/Messages";
import CountrySelect from "../../../../components/address/CountrySelect";
import { ICountry } from "../../../../interface/address";

export interface IPrivacyInfoFormProps {
    customerForm: any;
}

// @ts-ignore
export const PrivacyInfoSchema = Yup.object().shape<any>({
    nationality: Yup.object().required("Required"),
    citizenId: Yup.string().when(
        "nationality",
        (nationality: ICountry, yup: any) => {
            if (nationality?.primary) {
                return Yup.string()
                    .length(13, "Citizen ID must contain 13 digit characters")
                    .required("Required")
                    .nullable();
            }
            return Yup.string().nullable();
        }
    ) as any,
});

const PrivacyInfoForm: React.FC<IPrivacyInfoFormProps> = ({ customerForm }) => {
    const inputClassName = "col-span-12 md:col-span-6 mt-3";
    const formValues = customerForm?.values;
    const formErrors = customerForm?.errors;

    const { nationality } = formValues || {};

    return (
        <div className="md:p-3 grid grid-cols-12 md:gap-4">
            <CountrySelect
                value={formValues?.nationality}
                onChange={(value) =>
                    customerForm.setFieldValue("nationality", value)
                }
                className={inputClassName}
                error={formErrors?.nationality}
            />
            <InputTextForm
                keyData="citizenId"
                form={customerForm}
                className={inputClassName}
                required={nationality?.primary}
                disabled={!nationality}
            />
            <InputTextForm
                keyData="passportNo"
                form={customerForm}
                className={inputClassName}
            />
            <DateInput
                label={Messages.passportExp}
                onChange={(value) =>
                    customerForm.setFieldValue("passportExp", value)
                }
                value={
                    formValues.passportExp
                        ? moment(formValues.passportExp)
                        : (null as any)
                }
                error={formErrors.passportExp as any}
                format="DD/MM/YYYY"
                className={inputClassName}
            />

            <InputSelectForm
                keyData="religion"
                form={customerForm}
                dataSource={RELIGIONS}
                className={inputClassName}
            />
            <InputSelectForm
                keyData="maritalStatus"
                form={customerForm}
                dataSource={MARITAL_STATUS}
                className={inputClassName}
            />
            <InputTextForm
                keyData="numberOfChild"
                form={customerForm}
                className={inputClassName}
            />
        </div>
    );
};

export default PrivacyInfoForm;
