import { gql } from "@apollo/client";
import { map } from "lodash";
import { appStateDefault, IAppState } from "../../context/quotation";
import API from "../API";
import { F_PAYMENT_METHOD } from "../order/OrderFragment";
import { F_QUOTATION } from "./QuotationFragment";

const detailConvertor = {
    fromServer: (res: any) => res?.data?.detailQuotationForCustomer?.data,
};

const paymentMethodConvertor = {
    fromServer: (res: any) => res?.data?.paymentMethods ?? [],
};

const paymentListConvertor = {
    fromServer: (res: any) => res?.data?.data?.data ?? [],
};

export default {
    detail: (id: string) =>
        API.withConverter(detailConvertor).query({
            query: gql`
                ${F_QUOTATION}
                query detailQuotationForCustomer($id: String!) {
                    detailQuotationForCustomer(id: $id) {
                        data {
                            ...F_QUOTATION
                        }
                    }
                }
            `,
            variables: { id },
        }),

    updateContact: (id: string, payload: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_QUOTATION}
                mutation updateQuotationForCustomer(
                    $id: String!
                    $payload: UpdateCustomerQuotationRequest!
                ) {
                    detailQuotationForCustomer: updateQuotationForCustomer(
                        id: $id
                        payload: $payload
                    ) {
                        data {
                            ...F_QUOTATION
                        }
                    }
                }
            `,
            variables: { id, payload },
        }),

    updateServiceAddress: (
        refId: string,
        shippingType: string,
        id: string,
        payload: any
    ) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                mutation updateShippingForCustomer(
                    $refId: String!
                    $shippingType: ShippingType!
                    $id: String!
                    $payload: UpdateCustomerShippingRequest!
                ) {
                    detailQuotationForCustomer: updateShippingForCustomer(
                        refId: $refId
                        shippingType: $shippingType
                        id: $id
                        payload: $payload
                    ) {
                        data {
                            id
                        }
                    }
                }
            `,
            variables: { refId, shippingType, id, payload },
        }),

    loadPayments: (refId: string) =>
        API.withConverter(paymentListConvertor).query({
            query: gql`
                query listAllPaymentForCustomer($refId: String!) {
                    data: listAllPaymentForCustomer(refId: $refId) {
                        data {
                            id
                            paymentNo
                            total
                            status
                            paymentMethod
                            sourceOfPayment
                            paymentOption
                            hasOrder
                            refId
                            hasPermissionUploadProofForCustomer
                            proof {
                                nameOfPayer
                                transactionId
                                dateOfPayment
                                remark
                                attachments
                                at
                            }
                        }
                    }
                }
            `,
            variables: { refId },
            fetchPolicy: "no-cache",
        }),

    createProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_QUOTATION}
                mutation createProofPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: ProofPaymentInput
                ) {
                    order: createProofPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_QUOTATION
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),

    confirmProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_QUOTATION}
                mutation confirmProofPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: ConfirmProofPaymentInput
                ) {
                    order: confirmProofPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_QUOTATION
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),
    rejectProofPayment: (orderId: string, paymentId: string, input: any) =>
        API.withConverter(detailConvertor).mutate({
            mutation: gql`
                ${F_QUOTATION}
                mutation rejectProofPayment(
                    $orderId: ID!
                    $paymentId: ID!
                    $input: RejectProofPaymentInput
                ) {
                    order: rejectProofPayment(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                        input: $input
                    ) {
                        ...F_QUOTATION
                    }
                }
            `,
            variables: { orderId, paymentId, input },
        }),

    loadPaymentMethod: () =>
        API.withConverter(paymentMethodConvertor).query({
            query: gql`
                ${F_PAYMENT_METHOD}
                query loadMetaData {
                    provinces {
                        id
                        name
                    }

                    nationality

                    paymentMethods {
                        ...F_PAYMENT_METHOD
                    }
                }
            `,
        }),

    payment2C2P: (orderId: string, paymentId: string) =>
        API.mutate({
            mutation: gql`
                mutation payment2C2P($orderId: ID!, $paymentId: ID!) {
                    paymentToken(
                        orderId: $orderId
                        splitPaymentId: $paymentId
                    ) {
                        webPaymentUrl
                        paymentToken
                        respCode
                        respDesc
                    }
                }
            `,
            variables: { orderId, paymentId },
        }),
};
