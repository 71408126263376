import { gql } from "@apollo/client";
import API from "../API";
import { F_BRANCHES, F_BRANCH_DETAIL } from "./BranchFragment";

export default {
    list: (paginate: any) =>
        API.query({
            query: gql`
                ${F_BRANCHES}
                query listBranch($paginate: BranchPaginateRequest!) {
                    data: listBranchForCustomer(paginate: $paginate) {
                        ...F_BRANCHES
                    }
                }
            `,
            variables: { paginate },
        }),
    detail: (id: string) =>
        API.query({
            query: gql`
                ${F_BRANCH_DETAIL}
                query detailBranchForCustomer($id: String!) {
                    data: detailBranchForCustomer(id: $id) {
                        data {
                            ...F_BRANCH_DETAIL
                        }
                    }
                }
            `,
            variables: { id },
            fetchPolicy: "no-cache",
        }),
};
