import { InputText, IFormItemData, Form, Icon } from "d-react-components";
import { FormikProps } from "formik";
import React from "react";
import * as Yup from "yup";
import InputTextForm from "../../../../components/InputTextForm";
import Messages from "../../../../languages/Messages";
import PhoneCodeSelect from "../../../../components/address/PhoneCodeSelect";

export interface IContactInfoFormProps {
    customerForm: FormikProps<any>;
}

export const ContactSchema = Yup.object().shape({
    phone: Yup.string()
        .min(10, "Phone number is too short!")
        .max(14, "Phone number is too long!")
        .required("Required field!"),
    email: Yup.string().email().nullable(),
});

const ContactInfoForm: React.FC<IContactInfoFormProps> = ({ customerForm }) => {
    const inputClassName = "col-span-12 md:col-span-6 mt-3";
    const formValues = customerForm?.values;
    const formErrors = customerForm?.errors;
    return (
        <div className="grid grid-cols-12 md:gap-4">
            <div className={`${inputClassName} `}>
                <label className="mb-0">{Messages.phone}</label>
                <div className={`flex items-start `}>
                    <PhoneCodeSelect
                        required
                        disabled
                        className="w-fit"
                        allowClear={false}
                        showLabel={false}
                        value={formValues?.phoneCode}
                        onChange={(v) =>
                            customerForm.setFieldValue("phoneCode", v)
                        }
                        error={formErrors?.phoneCode}
                    />
                    <InputTextForm
                        disabled
                        className="w-full col-span-6"
                        showLabel={false}
                        keyData="phone"
                        form={customerForm}
                        required
                        suffix={<Icon name="check_circle" color="green" />}
                    />
                </div>
            </div>
            <InputTextForm
                label={Messages.alternativePhoneNo}
                keyData="alternativePhone"
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="email"
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="lineId"
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="instagram"
                form={customerForm}
                className={inputClassName}
            />
            <InputTextForm
                keyData="facebook"
                form={customerForm}
                className={inputClassName}
            />
        </div>
    );
};

export default ContactInfoForm;
