import { ObjectUtils, Select } from "d-react-components";
import { isArray, map, uniqBy } from "lodash";
import React, { useEffect, useMemo, useState } from "react";
import AddressAPI from "../../api/address/AddressAPI";
import { ICountry } from "../../interface/address";
import { IFieldSelect } from "../../interface/select";
import Messages from "../../languages/Messages";

export interface IPhoneCodeSelectProps<T> extends IFieldSelect<T> {
    [key: string]: any;
    showLabel?: boolean;
}

export const convertBase64ToImgSource = (base64: any) => {
    return `data:image/png;base64, ${base64}`;
};

const PhoneCodeSelect: React.FC<IPhoneCodeSelectProps<ICountry>> = ({
    value = [],
    multiple,
    className,
    onChange,
    showLabel = true,
    label,
    ...selectProps
}) => {
    const [textSearch, setTextSearch] = useState("");
    const [countryList, setCountryList] = useState<any[]>(
        isArray(value) ? value : [value]
    );

    useEffect(() => {
        AddressAPI.countryPhoneCodeList({
            page: 1,
            limit: 10000,
            search: textSearch ?? "",
        }).then((res: any) => {
            const countryRes = res ?? [];
            const selectedValue = isArray(value) ? value : [value];
            const filterCountry = map(
                [...selectedValue, ...countryRes],
                (item) => ({
                    ...item,
                    id: item.id,
                    name: item.name,
                })
            );
            const uniqCountry = uniqBy(filterCountry, (item) => item.id);
            setCountryList(uniqCountry);
        });
    }, [textSearch]);

    const onChangeValue = (id: any) => {
        if (multiple) {
            onChange(
                map(id, (item) => ObjectUtils.findItemFromId(countryList, item))
            );
            return;
        }
        onChange(ObjectUtils.findItemFromId(countryList, id));
    };

    const countryValue = useMemo(() => {
        if (multiple) {
            return map(value, (item: any) => item.id);
        }
        return (value as any)?.id;
    }, [value]);
    return (
        <Select
            className={className}
            label={showLabel ? label || Messages.country : undefined}
            dataSource={countryList}
            getLabel={(item) => (
                <div className="flex items-center">
                    <div className="mr-2">
                        <img
                            style={{ width: "25px" }}
                            src={convertBase64ToImgSource(item?.flag)}
                        />
                    </div>
                    <div>{item?.phoneCode}</div>
                </div>
            )}
            value={countryValue}
            onChange={onChangeValue}
            multiple={multiple}
            placeholder={Messages.code}
            showSearch
            filterOption={false}
            onSearch={(text) => setTextSearch(text)}
            {...selectProps}
        />
    );
};

export default PhoneCodeSelect;
