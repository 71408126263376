import LocalizedStrings from "react-localization";
import en from "./en";
import th from "./th";

const Messages: any = new LocalizedStrings({
    en,
    // th,
});

export default Messages;
