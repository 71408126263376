import { gql } from "@apollo/client";
import { F_PAGINATE } from "../app";
import { F_CUSTOMER_ADDRESS, F_COUNTRY } from "../location/LocationFragment";

export const F_CUSTOMER_PREFORM = gql`
    ${F_COUNTRY}
    ${F_CUSTOMER_ADDRESS}
    fragment F_CUSTOMER_PREFORM on CustomerUserDto {
        id
        avatar
        customerNo
        title
        fullNameTh
        fullNameEn
        firstNameTh
        lastNameTh
        firstNameEn
        lastNameEn
        nickname
        gender
        birthDay
        citizenId
        nationality {
            ...F_COUNTRY
        }
        passportNo
        passportExp
        religion
        maritalStatus
        numberOfChild
        phone
        alternativePhone
        email
        lineId
        instagram
        facebook
        address {
            ...F_CUSTOMER_ADDRESS
        }
        familyInformation {
            name
            relationship
            dateOfBirth
            phone
        }
        emergencyContact {
            name
            relationship
            residence
            phone
        }
        branch {
            id
            name
            code
        }
        createdAt
    }
`;

export const F_CUSTOMER_SURVEY = gql`
    fragment F_CUSTOMER_SURVEY on SurveyDto {
        id
        label
        type
        translate {
            language
            label
        }
        answers {
            id
            label
            translate {
                language
                label
            }
        }
    }
`;
