import { filter, map } from "lodash";
import moment from "moment";
import { IBillingAddress, ICountry, IServiceAddress } from "./address";

export enum SurveyQuestionType {
    INPUT = "INPUT",
    CHECK_BOX = "CHECK_BOX",
}

export interface ICustomerEmergencyContact {
    residence: string;
    name: string;
    phone: string;
    relationship: string;
}

export interface ICustomerFamilyInfo {
    dateOfBirth: string;
    name: string;
    phone: string;
    relationship: string;
}

export interface ISurvey {
    id: string;
    label: string;
    type?: SurveyQuestionType;
    translate?: any[];
    answers?: ISurvey[];
}

export interface ICustomer {
    id?: string;
    customerNo?: string;
    branch?: any;
    title?: string;
    avatar?: string;
    billing?: IBillingAddress[];
    birthDay?: string;
    citizenId?: string;
    createBy?: string;
    createdAt?: string;
    email?: string;
    emergencyContact: ICustomerEmergencyContact[];
    facebook?: string;
    instagram?: string;
    familyInformation?: ICustomerFamilyInfo[];
    firstNameEn: string;
    firstNameTh: string;
    fullNameTh?: string;
    fullNameEn?: string;
    gender?: "MALE" | "FEMALE" | "NOT_SAY";
    lastNameEn: string;
    lastNameTh: string;
    lineId?: string;
    maritalStatus?: string;
    nationality?: ICountry;
    phoneCodeCountry?: ICountry;
    nickname?: string;
    numberOfChild?: any;
    passportExp?: string;
    passportNo?: string;
    phone: string;
    phoneCode: string;
    alternativePhone?: string;
    religion?: string;
    updatedAt?: string;
    address?: IServiceAddress[];
}

export const mapCustomerPersonalInfoToServer = (customer: ICustomer) => {
    const {
        avatar,
        title,
        firstNameTh,
        firstNameEn,
        lastNameEn,
        lastNameTh,
        nickname,
        gender,
        birthDay,
    } = customer as any;
    return {
        avatar,
        title,
        firstNameTh,
        firstNameEn,
        lastNameEn,
        lastNameTh,
        nickname,
        gender,
        birthDay: birthDay ? moment(birthDay).toDate() : null,
    };
};

export const mapCustomerPrivacyInfoToServer = (customer: ICustomer) => {
    const {
        citizenId,
        nationality,
        passportNo,
        passportExp,
        religion,
        maritalStatus,
        numberOfChild,
    } = customer as any;
    return {
        citizenId,
        nationality: nationality?.id,
        passportNo,
        passportExp,
        religion,
        maritalStatus,
        numberOfChild: parseInt(numberOfChild, 10),
    };
};

export const mapCustomerContactToServer = (customer: ICustomer) => {
    const {
        phone,
        email,
        lineId,
        instagram,
        facebook,
        alternativePhone,
        phoneCode,
    } = customer as any;
    return {
        phone,
        phoneCodeCountry: phoneCode?.id,
        email,
        lineId,
        instagram,
        facebook,
        alternativePhone,
    };
};

export const mapCustomerFamilyInfoToServer = (customer: ICustomer) => {
    const { familyInformation } = customer as any;
    return {
        familyInformation: map(
            filter(familyInformation, (item) => item?.name && item?.phone),
            (item) => ({
                name: item.name,
                relationship: item.relationship,
                dateOfBirth: item.dateOfBirth,
                phone: item.phone,
            })
        ),
    };
};

export const mapCustomerEmergencyToServer = (customer: ICustomer) => {
    const { emergencyContact } = customer as any;
    return {
        emergencyContact: map(
            filter(emergencyContact, (item) => item?.name && item?.phone),
            (item) => {
                return {
                    name: item.name,
                    relationship: item.relationship,
                    residence: item.residence,
                    phone: item.phone,
                };
            }
        ),
    };
};

export const mapCustomerToServer = (customer: ICustomer) => {
    const personalInfo = mapCustomerPersonalInfoToServer(customer);
    const privacyInfo = mapCustomerPrivacyInfoToServer(customer);
    const contact = mapCustomerContactToServer(customer);
    const familyInfo = mapCustomerFamilyInfoToServer(customer);
    const emergencyContact = mapCustomerEmergencyToServer(customer);
    const { branch } = customer as any;
    return {
        ...personalInfo,
        ...privacyInfo,
        ...contact,
        ...familyInfo,
        ...emergencyContact,
        branchId: branch?.id,
    };
};

export const mapCustomerMedicalProfileToServer = (profile: any) => {
    const { height, weight, bloodGroup, bloodPressure } = profile;
    return {
        height: parseFloat(height),
        weight: parseFloat(weight),
        bloodGroup,
        bloodPressure,
    };
};

export const mapCustomerFilterBodyToServer = (filterBody: any) => {
    if (!filterBody) return {};
    const { gender, province, status, typeOfCustomer } = filterBody;
    return {
        gender,
        province,
        status,
        typeOfCustomer,
    };
};
