import React from "react";
import { ICountry, IProvince } from "../interface/address";
import { IOrder } from "../interface/order";
import { IPaymentMethod } from "../interface/paymentMethod";

export interface IAppState {
    paymentMethods?: IPaymentMethod[];
    provinceList?: IProvince[];
    countryList?: ICountry[];
    orderDetail: IOrder;
    setOrderDetail: any;
    shippings?: any[];
    loadShippingList?: any;
    splitPayment?: any[];
    loadPaymentList?: any;
    loadOrderDetail?: any;
    branch?: any;
}

export const appStateDefault: IAppState = {
    paymentMethods: [],
    countryList: [],
    provinceList: [],
    orderDetail: {} as any,
    setOrderDetail: () => {},
};

export const OrderContext = React.createContext(appStateDefault);
