import DevConfig from "./DevConfig";
import ProConfig from "./ProConfig";
import LocalConfig from "./LocalConfig";
import StagingConfig from "./StagingConfig";

const ENV = {
    DEV: "dev",
    PRO: "production",
    LOCAL: "local",
    STAGING: "staging",
};

const getConfig = () => {
    switch (process.env.REACT_APP_ENV) {
        case ENV.PRO:
            return ProConfig;
        case ENV.STAGING:
            return StagingConfig;
        case ENV.LOCAL:
            return LocalConfig;
        default:
            return DevConfig;
    }
};

export const CONFIG: { [key in keyof typeof DevConfig]: any } =
    getConfig() as any;
