/* eslint-disable consistent-return */
import {
    Button,
    Modal,
    Notifications,
    Progress,
    StringUtils,
} from "d-react-components";
import { useFormik } from "formik";
import * as Yup from "yup";
import React, { useContext, useEffect, useState } from "react";
import AddressAPI from "../../../api/address/AddressAPI";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import InputTextForm from "../../../components/InputTextForm";
import OtpVerificationView from "../../../components/OtpVerificationView";
import Messages from "../../../languages/Messages";
import CountrySelect from "../../../components/address/CountrySelect";
import PhoneCodeSelect from "../../../components/address/PhoneCodeSelect";
import { RegistrationContext } from "../context/RegistrationContext";

export interface IRegistrationVerifyProps {
    [key: string]: any;
}

// @ts-ignore
export const RegistrationVerifySchema = Yup.object().shape<any>({
    code: Yup.string().required("Required"),
    phone: Yup.string()
        .min(10, "Phone number is too short!")
        .max(14, "Phone number is too long!")
        .required("Required field!"),
});

const RegistrationVerify: React.FC<IRegistrationVerifyProps> = ({ id }) => {
    const { customerId, isUpdateInfo, setAuthState, setFormValueState } =
        useContext(RegistrationContext);
    const inputClassName = "col-span-12 md:col-span-6 mt-3";
    const verifyForm = useFormik<any>({
        initialValues: {},
        onSubmit: () => {
            onSubmitHandler();
        },
        validateOnChange: false,
        validationSchema: RegistrationVerifySchema,
        enableReinitialize: true,
    });
    const { values, errors, setFieldValue, handleSubmit, setValues } =
        verifyForm;
    const [openOtpModal, setOpenOtpModal] = useState(false);

    useEffect(() => {
        AddressAPI.countryPrimary().then((res: any) => {
            const countryRes = res ?? [];
            setValues((v: any) => ({
                ...v,
                phoneCode: countryRes?.[0],
                nationality: countryRes?.[0],
            }));
        });
    }, []);

    const onSubmitHandler = () => {
        const { code, phone, phoneCode, nationality } = values;
        const payload = {
            code,
            phoneCode: phoneCode?.phoneCode,
            phone,
            customerId: customerId || undefined,
        };
        const validateApi = isUpdateInfo
            ? CustomerAPI.validateUpdateInfoCode
            : CustomerAPI.validateSelfRegistrationCode;
        return Progress.show(
            { method: validateApi, params: [payload] },
            (res: any) => {
                const data = res?.data?.data ?? {};

                const { accessToken, checkOtp, isValidCode, otpToken } = data;
                if (!isValidCode) {
                    return Notifications.showError(Messages.codeIsInvalid);
                }
                if (checkOtp && otpToken) {
                    setValues({ ...values, accessToken, otpToken });
                    setOpenOtpModal(true);
                } else {
                    setFormValueState({ phone, nationality, phoneCode });
                    setAuthState({ isAllowed: true, accessToken, code });
                }
            },
            (error: any) => {
                Notifications.showError(error?.message ?? "");
            }
        );
    };

    const verifyOtpCode = () => {
        const { otpCode, otpToken, accessToken } = values;
        return Progress.show(
            {
                method: CustomerAPI.verifyOtpPreForm,
                params: [{ otpCode, otpToken, accessToken }],
            },
            (res: any) => {
                const data = res?.data?.data ?? {};
                const { phone, phoneCode, nationality } = values;
                if (data?.isVerified && data?.accessToken) {
                    setFormValueState({ phone, nationality, phoneCode });
                    setAuthState({
                        isAllowed: true,
                        accessToken: data?.accessToken,
                    });
                }
            },
            (error: any) => {
                Notifications.showError(error?.message ?? "");
            }
        );
    };

    const renderVerifyForm = () => {
        return (
            <div>
                <InputTextForm
                    label={Messages.secretCode}
                    keyData="code"
                    form={verifyForm}
                    className={inputClassName}
                    required
                />
                <CountrySelect
                    allowClear={false}
                    className={inputClassName}
                    label={Messages.nationality}
                    value={values?.nationality}
                    onChange={(v) => setFieldValue("nationality", v)}
                    error={errors?.nationality}
                    required
                />

                <div className={`${inputClassName} `}>
                    <label className="mb-3">{Messages.phone}</label>
                    <div className={`flex items-start `}>
                        <PhoneCodeSelect
                            required
                            className=""
                            allowClear={false}
                            showLabel={false}
                            value={values?.phoneCode}
                            onChange={(v) => setFieldValue("phoneCode", v)}
                            error={errors?.phoneCode}
                        />
                        <InputTextForm
                            className="w-full"
                            showLabel={false}
                            keyData="phone"
                            form={verifyForm}
                            required
                        />
                    </div>
                </div>

                <Button
                    className="my-4"
                    style={{ width: "100%" }}
                    onClick={handleSubmit as any}
                >
                    {Messages.getStart}
                </Button>
            </div>
        );
    };

    return (
        <div className="mr-auto ml-auto min-h-screen flex flex-col mt-12 md:pt-36">
            <div className="uppercase text-primary text-4xl text-center font-bold">
                {Messages.newPatientRegistration}
            </div>
            <p className="mt-3">{Messages.registrationVerifyDescription}</p>
            <p>{Messages.registrationVerifyDescription2}</p>
            {renderVerifyForm()}
            <Modal
                title={Messages.phoneNumberVerification}
                onClose={() => {
                    setOpenOtpModal(false);
                }}
                open={openOtpModal}
                saveText={Messages.verify}
                onSave={verifyOtpCode}
            >
                <OtpVerificationView
                    phone={values?.phone}
                    onChangeCode={(code: any) => setFieldValue("otpCode", code)}
                />
            </Modal>
        </div>
    );
};

export default RegistrationVerify;
