const version = 1;

export const saveToLocal = (key: string, state: any) => {
    try {
        localStorage.setItem(key, JSON.stringify(state));
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }
};

export const loadFromLocal = (key: string) => {
    let state;
    try {
        state = localStorage.getItem(key);

        if (typeof state === "string") {
            state = JSON.parse(state);
        }

        // if (state && state.version !== version) {
        //     state = undefined;
        // }
    } catch (error) {
        // eslint-disable-next-line no-console
        console.error(error);
    }

    return state || undefined;
};
