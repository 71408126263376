import { ApolloLink } from "@apollo/client";
import { UrlUtils } from "d-react-components";
import { LANGUAGE_DEFAULT } from "../constant/app";
import Messages from "../languages/Messages";

export const AuthMiddleware = new ApolloLink((operation, forward) => {
    try {
        const accessToken = "";
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                Authorization: `Bearer ${accessToken}`,
            },
        }));
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log({ error });
    }

    return forward(operation);
});

export const LangMiddleware = new ApolloLink((operation, forward) => {
    try {
        const { locale = LANGUAGE_DEFAULT } = UrlUtils.getQuery();
        operation.setContext(({ headers = {} }) => ({
            headers: {
                ...headers,
                language: locale,
            },
        }));
    } catch (error: any) {
        // eslint-disable-next-line no-console
        console.log({ error });
    }

    return forward(operation);
});
