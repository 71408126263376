import moment, { Moment } from "moment";

export function getSecondFromTime(time: Moment) {
    const miliSec = (moment(time) as any) - (moment(time).startOf("d") as any);
    const sec = Math.floor(miliSec / 1000);
    return sec;
}

export function fmtMSS(e: number) {
    const h = Math.floor(e / 3600)
        .toString()
        .padStart(2, "0");
    const m = Math.floor((e % 3600) / 60)
        .toString()
        .padStart(2, "0");

    return `${h}:${m}`;
}
