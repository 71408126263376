import { find } from "lodash";

export const addProductChildren = (row: any) => {
    if (row?.groups?.length > 0 || row?.premiumService?.product?.length > 0) {
        return {
            children: [
                ...(row.typeOfProduct === "GROUP_PRODUCT" ||
                row.typeOfProduct === "SPECIAL_GROUP_SERVICE" ||
                row.typeOfProduct === "BUNDLE_PRODUCT"
                    ? row?.groups ?? []
                    : []),
                // ...(row.typeOfProduct === "PREMIUM_SERVICE"
                //     ? row?.premiumService?.product?.map((row: any) => ({
                //           ...row,
                //           product: row,
                //       })) ?? []
                //     : []),
            ].map((item) => ({
                ...item,
                isChildren: true,
            })),
        };
    }
    return {};
};

export const mergeProducts = (normalProduct: any, manualProduct: any) => {
    const products =
        normalProduct?.map((product: any) => ({
            ...product,
            ...addProductChildren(product),
            id: product?.product?.id,
        })) ?? [];
    const manualProducts =
        manualProduct
            ?.map((product: any) => ({
                ...product,
                id: product.id,
                isCustomProduct: true,
                product: {
                    name: product.name,
                    sku: product.sku,
                },
                ...addProductChildren(product),
            }))
            .filter((item: any) => {
                if (item.id) {
                    return !find(
                        products,
                        (product) => item.id === product.manualProduct
                    );
                }
                return true;
            }) ?? [];
    return [...products, ...manualProducts];
};
