/* eslint-disable consistent-return */
import { Button, Notifications, Progress } from "d-react-components";
import { useFormik } from "formik";
import React, { useContext } from "react";
import * as Yup from "yup";
import CustomerAPI from "../../../api/customer/CustomerAPI";
import InputTextForm from "../../../components/InputTextForm";
import Messages from "../../../languages/Messages";
import { HealthAssessmentContext } from "../HealthAssessment";

export interface IRegistrationVerifyProps {
    [key: string]: any;
}

// @ts-ignore
export const HealthAssessmentVerifySchema = Yup.object().shape<any>({
    code: Yup.string().required("Required"),
});

const RegistrationVerify: React.FC<IRegistrationVerifyProps> = ({ id }) => {
    const { customerId, setAuthState, setFormValueState } = useContext(
        HealthAssessmentContext
    );
    const inputClassName = "col-span-12 md:col-span-6 mt-3";
    const verifyForm = useFormik<any>({
        initialValues: {},
        onSubmit: () => {
            onSubmitHandler();
        },
        validateOnChange: false,
        validationSchema: HealthAssessmentVerifySchema,
        enableReinitialize: true,
    });
    const { values, errors, setFieldValue, handleSubmit, setValues } =
        verifyForm;

    const onSubmitHandler = () => {
        const { code, phone, phoneCode, nationality } = values;
        const payload = {
            code,
            customerId: customerId || undefined,
        };
        const validateApi = CustomerAPI.validateHealthAssessmentCode;
        return Progress.show(
            { method: validateApi, params: [payload] },
            (res: any) => {
                const data = res?.data?.data ?? {};

                const { accessToken, checkOtp, isValidCode, otpToken } = data;
                if (!isValidCode || !accessToken) {
                    return Notifications.showError(Messages.codeIsInvalid);
                }
                setAuthState({ isAllowed: true, accessToken });
            },
            (error: any) => {
                Notifications.showError(error?.message ?? "");
            }
        );
    };

    const renderVerifyForm = () => {
        return (
            <React.Fragment>
                <InputTextForm
                    label={Messages.secretCode}
                    keyData="code"
                    form={verifyForm}
                    className={inputClassName}
                    required
                />
                <Button
                    className="my-4"
                    style={{ width: "100%" }}
                    onClick={handleSubmit as any}
                >
                    {Messages.getStart}
                </Button>
            </React.Fragment>
        );
    };

    return (
        <div className="mr-auto ml-auto min-h-screen flex flex-col mt-12 md:pt-36">
            <div className="uppercase text-primary text-4xl text-center font-bold">
                {Messages.healthSelfAssessmentForm}
            </div>
            <p className="mt-3">{Messages.healthSelfAssessmentFormDesc}</p>
            <p>{Messages.healthSelfAssessmentFormDesc2}</p>
            {renderVerifyForm()}
        </div>
    );
};

export default RegistrationVerify;
