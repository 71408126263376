import { StringUtils } from "d-react-components";
import { map } from "lodash";
import { useContext, useMemo } from "react";
import CurrencyFormat from "react-currency-format";
import { mergeProducts } from "../../api/util/product";
import SectionHeader from "../../components/SectionHeader";
import { QuotationContext } from "../../context/quotation";
import { IQuotation } from "../../interface/quotation";
import Messages from "../../languages/Messages";
import { OrderProductItem } from "../order/OrderProductsMobile";
import ManualDiscount from "./ManualDiscount";

const OrderProductsMobile = () => {
    const { quotationDetail } = useContext(QuotationContext);
    const {
        products: normalProduct,
        manualProduct,
        subtotal,
        total,
        totalVat,
        vouchers,
        manualDiscount,
        fee,
    } = quotationDetail as IQuotation;

    const products = useMemo(() => {
        return mergeProducts(normalProduct, manualProduct);
    }, [quotationDetail]);

    const renderSummaryRow = (title: any, content: any) => (
        <div className="d-flex justify-content-between mt-1">
            <label>{title}</label>
            <text>{content}</text>
        </div>
    );

    const renderSummaryPriceRow = (title: any, price: number) =>
        renderSummaryRow(
            title,
            // StringUtils.moneyThaiFormat(price)
            <CurrencyFormat
                displayType="text"
                thousandSeparator
                value={price}
                decimalScale={2}
                fixedDecimalScale
            />
        );

    const renderSummaryVoucherRow = (title: any, price: number) => {
        return renderSummaryRow(
            title,
            // <text className="text-success">
            //     {`-${StringUtils.moneyThaiFormat(price)}`}
            // </text>
            <CurrencyFormat
                displayType="text"
                thousandSeparator
                value={-price}
                decimalScale={2}
                fixedDecimalScale
            />
        );
    };

    const renderOrderSummary = () => (
        <div>
            <SectionHeader label={Messages.summary} hiddenEdit />
            {renderSummaryPriceRow(Messages.subTotal, subtotal)}
            {map(vouchers, (voucher: any) =>
                renderSummaryVoucherRow(
                    `Voucher (${voucher.code})`,
                    voucher?.discount
                )
            )}
            <ManualDiscount manualDiscount={manualDiscount} isMobile />

            {map(fee, (fee: any) => {
                return renderSummaryPriceRow(
                    (Messages as any)[fee?.name as string] || fee?.name,
                    fee?.total
                );
            })}

            {renderSummaryRow(
                <div>{Messages.taxIncluded}</div>,
                // <div>{StringUtils.moneyThaiFormat((total * 7) / 100)}</div>
                <CurrencyFormat
                    displayType="text"
                    thousandSeparator
                    value={totalVat}
                    decimalScale={2}
                    fixedDecimalScale
                />
            )}
            {renderSummaryRow(
                Messages.total,
                // <div className="text-bold">
                //     {StringUtils.moneyThaiFormat(total)}
                // </div>
                <CurrencyFormat
                    displayType="text"
                    thousandSeparator
                    value={total}
                    decimalScale={2}
                    fixedDecimalScale
                />
            )}
            <div
                className="p-3 text w-100 bg-note mt-1"
                hidden={!quotationDetail.isInvoice}
            >
                This customer requested the tax invoice
            </div>
        </div>
    );

    return (
        <div className="display-mobile-layout">
            <div className="card-container mt-3 p-4 flex-column">
                <SectionHeader label={Messages.items} hiddenEdit />
                {map(products, (product) => (
                    <OrderProductItem
                        product={product?.product}
                        quantity={product.quantity}
                    />
                ))}
            </div>
            <div className="card-container mt-3 p-4 ">
                {renderOrderSummary()}
            </div>
        </div>
    );
};

export default OrderProductsMobile;
