import { gql } from "@apollo/client";
import API from "../API";
import { F_CUSTOMER_PREFORM, F_CUSTOMER_SURVEY } from "./CustomerFragment";

export default {
    validateSelfRegistrationCode: (input: any) =>
        API.query({
            query: gql`
                query validateSelfRegistrationCode(
                    $input: CustomerAccessFormRequest!
                ) {
                    data: validateSelfRegistrationCodeForCustomer(
                        input: $input
                    ) {
                        isValidCode
                        checkOtp
                        accessToken
                        otpToken
                    }
                }
            `,
            variables: { input },
        }),

    validateUpdateInfoCode: (input: any) =>
        API.query({
            query: gql`
                query validateUpdateInfoCode(
                    $input: CustomerAccessFormRequest!
                ) {
                    data: validateUpdateInfoCodeForCustomer(input: $input) {
                        isValidCode
                        checkOtp
                        accessToken
                        otpToken
                    }
                }
            `,
            variables: { input },
        }),

    validateHealthAssessmentCode: (input: any) =>
        API.query({
            query: gql`
                query validateHealthAssessmentCode(
                    $code: String!
                    $customerId: String!
                ) {
                    data: validateHealthAssessmentCodeForCustomer(
                        code: $code
                        customerId: $customerId
                    ) {
                        isValidCode
                        accessToken
                    }
                }
            `,
            variables: { ...input },
        }),

    verifyOtpPreForm: (input: {
        otpToken: string;
        otpCode: string;
        accessToken: string;
    }) =>
        API.query({
            query: gql`
                query verifyOtpPreForm(
                    $otpToken: String!
                    $otpCode: String!
                    $accessToken: String!
                ) {
                    data: verifyOtpPreFormForCustomer(
                        otpToken: $otpToken
                        otpCode: $otpCode
                        accessToken: $accessToken
                    ) {
                        isVerified
                        accessToken
                    }
                }
            `,
            variables: { ...input },
        }),

    verifyPreFormAccessToken: (accessToken: string) =>
        API.query({
            query: gql`
                query verifyPreFormAccessToken($accessToken: String!) {
                    data: verifyPreFormAccessTokenForCustomer(
                        accessToken: $accessToken
                    ) {
                        isValidCode
                        accessToken
                    }
                }
            `,
            variables: { accessToken },
        }),

    loadPreFormData: (input: { customerId: string; accessToken: string }) =>
        API.query({
            query: gql`
                ${F_CUSTOMER_PREFORM}
                query loadPreFormData(
                    $customerId: String!
                    $accessToken: String!
                ) {
                    data: loadPreFormDataForCustomer(
                        customerId: $customerId
                        accessToken: $accessToken
                    ) {
                        data {
                            ...F_CUSTOMER_PREFORM
                        }
                    }
                }
            `,
            variables: { ...input },
        }),

    loadHealthAssessmentData: (input: {
        customerId: string;
        accessToken: string;
    }) =>
        API.query({
            query: gql`
                ${F_CUSTOMER_SURVEY}
                query loadHealthAssessmentData(
                    $customerId: String!
                    $accessToken: String!
                ) {
                    data: loadHealthAssessmentDataForCustomer(
                        customerId: $customerId
                        accessToken: $accessToken
                    ) {
                        formData {
                            ...F_CUSTOMER_SURVEY
                        }
                        surveyAnswers {
                            questionId
                            answers
                        }
                    }
                }
            `,
            variables: { ...input },
        }),

    createSelfRegistration: (payload: { input: any; accessToken: string }) =>
        API.mutate({
            mutation: gql`
                mutation createSelfRegistration(
                    $input: CustomerSelfRegistrationRequest!
                    $accessToken: String!
                ) {
                    data: createSelfRegistrationForCustomer(
                        input: $input
                        accessToken: $accessToken
                    )
                }
            `,
            variables: { ...payload },
        }),

    createRequestUpdateInfo: (payload: {
        customerId: string;
        input: any;
        accessToken: string;
    }) =>
        API.mutate({
            mutation: gql`
                mutation createRequestUpdateInfo(
                    $input: CustomerSelfRegistrationRequest!
                    $accessToken: String!
                    $customerId: String!
                ) {
                    data: createRequestUpdateInfoForCustomer(
                        input: $input
                        accessToken: $accessToken
                        customerId: $customerId
                    )
                }
            `,
            variables: { ...payload },
        }),

    createHealthAssessment: (payload: {
        customerId: string;
        input: any;
        accessToken: string;
    }) =>
        API.mutate({
            mutation: gql`
                mutation createHealthAssessment(
                    $input: HealthAssessmentAnswersRequest!
                    $accessToken: String!
                    $customerId: String!
                ) {
                    data: createHealthAssessmentForCustomer(
                        input: $input
                        accessToken: $accessToken
                        customerId: $customerId
                    )
                }
            `,
            variables: { ...payload },
        }),
};
